import React, { useState, useEffect, Fragment } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { logout, load_user } from '../../../actions/auth';
import { MySuppliers } from '../../../actions/SuppliersAPI';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../actions/types';

const SupplierLists = ({ display, SupplierID, CompanyID, BranchID }) => {
    const [Data, setData] = useState(null)
    const [Widget, setWidget] = useState(false)
    const [View, setView] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        My_Suppliers();
    }, [])

    const My_Suppliers = async () => {
        var result = await MySuppliers(CompanyID, BranchID);
        setData(result);
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const history = useHistory();
    // const FetchUser = async (id) => {
    //     var User_Data = await LoadProfile(id);
    //     history.push('/pending_user', { UserData: User_Data.data });
    // }

    return (
        <div className="row h-100 m-0 d-flex justify-content-center">

            <div className="header mb-4">
                <p className="display-6 d-flex justify-content-center m-0">My Supplier Lists</p>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb d-flex justify-content-center">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/supplier_list">My Suppliers</Link></li>
                    </ol>
                </nav>
            </div>

            <div className="col-lg-8 h-100 pl-0">
                <div div className="row d-flex bg-white mx-auto" >
                    <div className="d-flex justify-content-between p-0">

                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <button className="btn btn-success rounded-0 rounded-start" id="list" onClick={() => setView(true)}>
                                List View
                            </button>
                        </div>

                        <div className="d-flex justify-content-center mx-2" style={{ minWidth: "10vh" }}>
                            <input className="border-0 border-start rounded-pill px-2 min-vw-25" type="text" placeholder="Search Keywords" />
                            <p className='fw-bold text-success my-auto px-1' title="Search" type='button'>Search</p>
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button className="btn btn-success rounded-0 rounded-end" id="grid" onClick={() => setView(false)}>
                                Grid View
                            </button>
                        </div>

                    </div>
                </div>
                <div className="row position-absolute overflow-auto mx-auto mt-3 w-100" style={{ height: "70%" }}>

                    <div id="products" className="row view-group m-0 p-0">
                        {
                            Array.isArray(Data) && Data.length ? Data.map((item, i) => (
                                <div className={View ? "item col-xs-3 col-lg-3 grid-group-item mb-3" : "item col-xs-3 col-lg-3 grid-group-item list-group-item mb-3"} key={i}>
                                    <Link
                                        to={`/purchase_product/${item.SupplierID}`}
                                        className="box thumbnail card py-2 shadow-none m-0 h-100">
                                        <div className="img-event d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "12vh" }}>
                                            <img
                                                src={item.Logo ? process.env.REACT_APP_API_URL + item.Logo : process.env.REACT_APP_API_URL + "/Media/no_logo.jpeg"}
                                                className="img-fluid" alt="avatar"
                                                style={{ minWidth: "15vh" }} width="20px" height="20px" />
                                        </div>

                                        <div className="caption card-body d-flex flex-column justify-content-center py-0 px-2" style={{ minHeight: "12vh" }}>
                                            <p className="group inner list-group-item-text m-0">
                                                <p className="group card-title inner list-group-item-text fs-4 fw-bold m-0">{item.SupplierID + ". " + item.CmpName}</p>
                                                <p className="group card-title inner list-group-item-text fs-6 fw-bold m-0"><i class="fad fa-user-tie pr-2"></i>Agent Name</p>
                                                <small className="group card-title inner list-group-item-text fw-normal"><i class="fad fa-phone-alt pr-2"></i>{item.Contact}</small>
                                                <br /><small className="text-muted"><i class="fad fa-map-marked-alt pr-2"></i>{item.Address}</small>
                                            </p>
                                        </div>
                                    </Link>
                                </div>

                            )) :
                                <div className={`d-flex justify-content-center align-items-center bg-white`}>
                                    <h className='fs-2 fw-bold text-success'>No Supplier Found!</h>
                                </div>
                        }

                    </div>
                </div>
            </div >
        </div>
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    SupplierID: props.location.SupplierID,
    CompanyID: state.auth.user.CompanyID,
    BranchID: state.auth.user.BranchID
});

export default connect(mapStateToProps, { logout, load_user })(SupplierLists);