import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Switch, useHistory, Redirect } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

import { PrivateRoute } from "./hocs/PrivateRoute";
import { FieldWorkerRoute } from './hocs/FieldWorkerRoute';
import { BranchManagerRoute } from './hocs/BranchManagerRoute';

import FieldWorkLayout from './hocs/FieldWorkLayout';
import PublicLayout from './hocs/PublicLayout';
import Layout from './hocs/Layout';

import { RouteWithLayout } from './hocs/RouteLayout';

import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./hocs/css/light.css";
import "./hocs/css/style.css";

import Activate from './containers/PublicPages/Login_Reg_View/Activate';
import ResetPassword from './containers/PublicPages/Login_Reg_View/ResetPassword';
import ResetPasswordConfirm from './containers/PublicPages/Login_Reg_View/ResetPasswordConfirm';
import Signup from './containers/PublicPages/Login_Reg_View/Signup';
import Facebook from './containers/PublicPages/Login_Reg_View/Facebook';
import Google from './containers/PublicPages/Login_Reg_View/Google';
import Home from './containers/PublicPages/Home';
import Login from './containers/PublicPages/Login_Reg_View/Login';
import NotFound from './containers/PublicPages/NotFound';
import Block from './containers/PublicPages/Block';

import BusinessProfiles from './containers/CeoPages/BusinessProfiles';
import Dashboard from './containers/AuthPages/Dashboard/Dashboard';
import EmployeeMain from './containers/CeoPages/Employee/EmployeeMain';

import PendingUser from './containers/AuthPages/PendingUser';
import WaitingUsers from './containers/PublicPages/Login_Reg_View/WaitingUsers';
import Register from './containers/AuthPages/Register';
import LoadPending from './containers/AuthPages/LoadPending';


import CompanyRegister from './containers/PublicPages/Login_Reg_View/CompanyRegister';
import CreateBranch from './containers/CeoPages/CreateBranch';
import CreateCompany from './containers/AuthPages/Admin/CreateCompany';
import UpdateBranch from './containers/CeoPages/UpdateBranch';

//Profiles
import Profiles from './containers/AuthPages/Profiles/Profiles';
import MyUserLists from './containers/AuthPages/Profiles/MyUserLists';

//BusinessProfiles
import BusinessPro from './containers/AuthPages/Profiles/BisProfile/BisMain';

// Suppliers
// National Suppliers
import SupplierReg from './containers/Suppliers/Register';
import NationalSuppliers from './containers/Suppliers/NationalSuppliers/SupplierLists';
import SupplierPro from './containers/Suppliers/NationalSuppliers/Profiles/SupplierMain';
import ProductItems from './containers/Suppliers/NationalSuppliers/Profiles/ProductItems';

// My Suppliers
import MySupplierList from './containers/Suppliers/MySuppliers/SupplierLists';
import MySupplierMain from './containers/Suppliers/MySuppliers/Profiles/SupplierMain';

// Purchase
import SupplierList from './containers/Inventory/Purchase/SupplierLists';
import PurchaseProduct from './containers/Inventory/Purchase/PurchaseProduct';

// Daily Sell
import Sells from './containers/Inventory/DailySell/Sells';
import PrvInvoice from './containers/Inventory/Report/ViewInvoice/PrvInvoice';

// Stock
import Stock from './containers/Inventory/Stock/Stock';

//Contract Farm Management
import FarmMain from './containers/Contract/FarmManagement/FarmMain';
import FarmLists from './containers/Contract/FarmLists';
import SendProduct from './containers/Contract/Products/SendProduct';
import LiveStock from './containers/Contract/BirdStock/LiveStock';
import ContractDispatchReport from './containers/Contract/DispatchReport/ContractDispatchReport';
import PrvConInvoice from './containers/Contract/DispatchReport/ViewInvoice/PrvConInvoice';

// Accounts
import Voucher from './containers/Accounts/Voucher/Voucher';
import COA from './containers/Accounts/COA';
import AccountJournal from './containers/Accounts/AccountJournal';
import PaymentList from './containers/Accounts/Farmer Payment/PaymentList';

// Business Preference
import AccountSettings from './containers/BusinessPreference/AccSetttings/AccountSettings'
import ProfitMargin from './containers/BusinessPreference/ProfitMargin/ProfitMargin'
import Policy from './containers/BusinessPreference/Policy/Policy';

// Field Works
import FarmFields from './containers/FieldWork/FarmFields';
import LightEntry from './containers/FieldWork/LightEntry';
import BirdSell from './containers/FieldWork/BirdSell/BirdSell';
import SellReportField from './containers/FieldWork/SellReport/SellReport';
import FieldWorkerMenu from './containers/FieldWork/FieldWorkerMenu';
import Fieldboard from './containers/FieldWork/Dashboard';
import FarmLadger from './containers/FieldWork/FarmLadger';




import Report from './containers/Inventory/Report/Report';
import BirdSellHistory from './containers/Contract/BirdSellReport/BirdSellHistory';
import CashLadger from './containers/CashFlow/CashLadger';
import Disable from './containers/PublicPages/Disable';
import ProductRequisition from './containers/Inventory/Requisitions/ProductRequisition';
import RequisitionDraft from './containers/Inventory/Requisitions/RequisitionDraft';
import RequisitionHistory from './containers/Inventory/Requisitions/RequisitionHistory';
import RequisitionList from './containers/Inventory/Requisitions/RequisitionList';
import ReqExecute from './containers/Inventory/Requisitions/ReqExecute';
import PaymentProcedure from './containers/Accounts/Farmer Payment/PaymentRelease';
import BatchPayFields from './containers/FieldWork/BatchPayFields';
import FarmRecord from './containers/FieldWork/DailyRecord/FarmRecord';
import BatchAccounts from './containers/FieldWork/BatchAccount/BatchAccounts';
import { SalesRepresentativeRoute } from './hocs/SalesRepresentativeRoute';
import UpdateUser from './containers/AuthPages/Profiles/UpdateUser';
import { AdminRoute } from './hocs/AdminRoute';
import BisList from './containers/AuthPages/Admin/BisList';
import UserLists from './containers/AuthPages/Admin/UserLists';
import ResetPass from './containers/AuthPages/ResetPass';


const App = () => {
    const pathname = window.location.pathname
    const [list, setList] = useState([]);
    // const [ProductPro, setProductPro] = useState();
    const [SupplierID, setSupplierID] = useState();
    const [isActive, setActive] = useState(pathname === "/dashboard" ? 1 : "")
    const history = useHistory();
    return (
        // <Switch>
        //     <RouteWithLayout layout={PublicLayout} path="/" component={HomePage} />
        //     <RouteWithLayout layout={FieldWorkerRoute} path="/profile" component={ProfilePage} />
        //     <RouteWithLayout layout={Layout} path="/dashboard" component={DashboardPage} />
        // </Switch>

        // !localStorage.getItem("user") ?
        //     <Provider store={store}>
        //         <Router path='/' history={history}>
        //             <PublicLayout list={list} setList={setList}>
        //                 <Switch>

        //                 </Switch>
        //             </PublicLayout>
        //         </Router>
        //     </Provider>
        //     :
        //     JSON.parse(localStorage.getItem("accounts")) ?

        <Provider store={store}>
            <Router>
                <Layout list={list} setList={setList} setActive={setActive} isActive={isActive}>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/login' component={Login} />
                        <Route exact path='/signup' component={Signup} />
                        <Route exact path='/block' component={Block} />
                        <Route exact path='/disable' component={Disable} />

                        <PrivateRoute exact path='/' component={Dashboard} />
                        <PrivateRoute exact path='/waiting' component={WaitingUsers} />
                        <PrivateRoute exact path='/dashboard' component={Dashboard} />
                        <PrivateRoute exact path='/facebook' component={Facebook} />
                        <PrivateRoute exact path='/google' component={Google} />
                        <PrivateRoute exact path='/reset-password' component={ResetPassword} />
                        <PrivateRoute exact path='/password/reset/confirm/:uid/:token' component={ResetPasswordConfirm} />
                        <PrivateRoute exact path='/activate/:uid/:token' component={Activate} />
                        <PrivateRoute exact path='/LoadPending' component={LoadPending} />
                        <PrivateRoute exact path='/Register' render={(props) => <Register list={list} setList={setList} {...props} />} />
                        <PrivateRoute exact path='/pending_user' component={PendingUser} />

                        {/* Cash Flow */}
                        <PrivateRoute path='/cash_flow' render={(props) => <CashLadger list={list} setList={setList} {...props} />} />

                        {/* Field Works */}
                        <PrivateRoute path='/fields' render={(props) => <FarmFields list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/light_record/:bis_id/:id' render={(props) => <LightEntry list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/bird_sell/:bis_id/:id/:inv_no/:inv_id' render={(props) => <BirdSell list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/sell_report/:bis_id/:id/:inv_no/:inv_id' render={(props) => <SellReportField list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/field_worker' render={(props) => <FieldWorkerMenu list={list} setList={setList} {...props} />} />

                        {/* Accounts */}
                        <PrivateRoute path='/voucher' render={(props) => <Voucher list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/coa' render={(props) => <COA list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/acc_journal' render={(props) => <AccountJournal list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/farmer_payment_list' render={(props) => <PaymentList list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/farmer_payment_release/:id' render={(props) => <PaymentProcedure list={list} setList={setList} {...props} />} />

                        {/* Profiles */}
                        <PrivateRoute exact path='/my_user_lists' component={MyUserLists} />
                        <PrivateRoute exact path='/user_profile/:id' render={(props) => <Profiles list={list} setList={setList}  {...props} />} />
                        <PrivateRoute exact path='/update_user' render={(props) => <UpdateUser list={list} setList={setList}  {...props} />} />

                        <PrivateRoute exact path='/business_profiles' render={(props) => <BusinessProfiles list={list} setList={setList}  {...props} />} />
                        <PrivateRoute exact path='/employee_main/:id' render={(props) => <EmployeeMain list={list} setList={setList}  {...props} />} />
                        <PrivateRoute exact path='/update_branch' component={UpdateBranch} />
                        <PrivateRoute exact path='/create_branch' render={(props) => <CreateBranch list={list} setList={setList}  {...props} />} />

                        {/* Supplier PrivateRoute */}
                        {/* National Suppliers */}
                        <PrivateRoute exact path='/national_supplier_list' render={(props) => <NationalSuppliers list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/my_supplier_pro/:sup_id' render={(props) => <SupplierPro list={list} setList={setList} {...props} />} />
                        <PrivateRoute exact path='/product_item/:sup_id/:id' render={(props) => <ProductItems list={list} setList={setList} {...props} />} />

                        {/* Supplier PrivateRoute */}
                        {/* My Suppliers */}
                        <PrivateRoute exact path='/my_supplier_list' render={(props) => <MySupplierList list={list} setList={setList}  {...props} />} />
                        <PrivateRoute path='/my_supplier/:sup_id' render={(props) => <MySupplierMain list={list} setList={setList} {...props} />} />
                        {/* Supplier PrivateRoute */}

                        {/* Inventory */}
                        {/* Purchase */}
                        <PrivateRoute exact path='/supplier_items' render={(props) => <SupplierList setSupplierID={setSupplierID} SupplierID={SupplierID} list={list} setList={setList} {...props} />} />
                        <PrivateRoute exact path='/purchase_product/:sup_id' render={(props) => <PurchaseProduct setSupplierID={setSupplierID} SupplierID={SupplierID} list={list} setList={setList} {...props} />} />
                        {/* Daily Sells */}
                        <PrivateRoute exact path='/sells' render={(props) => <Sells setSupplierID={setSupplierID} SupplierID={SupplierID} list={list} setList={setList} {...props} />} />
                        <PrivateRoute exact path='/sell_reports' render={(props) => <Report list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/prv_sells_invoice/:inv_no' render={(props) => <PrvInvoice list={list} setList={setList} {...props} />} />

                        {/* Stock */}
                        <PrivateRoute exact path='/stock' render={(props) => <Stock setSupplierID={setSupplierID} SupplierID={SupplierID} list={list} setList={setList} {...props} />} />

                        {/* Bis Profile */}
                        <PrivateRoute path='/business_pro/:id/:bis_id' render={(props) => <BusinessPro list={list} setList={setList}  {...props} />} />


                        {/* Cotract */}
                        {/* Farm */}
                        <PrivateRoute path='/farm_mng/:id/:bis_id/:batch_id' render={(props) => <FarmMain list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/farm_lists' render={(props) => <FarmLists list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/send_products/:id' render={(props) => <SendProduct list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/live_stock' render={(props) => <LiveStock list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/bird_sell_history' render={(props) => <BirdSellHistory list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/con_dispatch_report' render={(props) => <ContractDispatchReport list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/prv_dispatch_invoice/:inv_no' render={(props) => <PrvConInvoice list={list} setList={setList} {...props} />} />


                        {/* Business Preference */}
                        <PrivateRoute path='/acc_settings' render={(props) => <AccountSettings list={list} setList={setList} {...props} />} />
                        <PrivateRoute path='/profit_margin' render={(props) => <ProfitMargin list={list} setList={setList} {...props} />} />
                        <PrivateRoute exact path='/policy' render={(props) => <Policy list={list} setList={setList} {...props} />} />


                        <PrivateRoute exact path='/req_list' render={(props) => <RequisitionList list={list} setList={setList} {...props} />} />
                        <PrivateRoute exact path='/req_execute/:req_no' render={(props) => <ReqExecute list={list} setList={setList} {...props} />} />
                        <PrivateRoute exact path='/pvt_reset_pass' render={(props) => <ResetPass list={list} setList={setList} {...props} />} />


                        <BranchManagerRoute exact path='/' component={Dashboard} />
                        <BranchManagerRoute exact path='/products_requisition' render={(props) => <ProductRequisition list={list} setList={setList} setActive={setActive} {...props} />} />
                        <BranchManagerRoute exact path='/req_draft' render={(props) => <RequisitionDraft list={list} setList={setList} {...props} />} />
                        <BranchManagerRoute exact path='/req_history' render={(props) => <RequisitionHistory list={list} setList={setList} {...props} />} />
                        <BranchManagerRoute exact path='/br_reset_pass' render={(props) => <ResetPass list={list} setList={setList} {...props} />} />


                        <FieldWorkerRoute exact path='/' component={Dashboard} />
                        <FieldWorkerRoute exact path='/' render={(props) => <FieldWorkerMenu list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/field_work' render={(props) => <FieldWorkerMenu list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/field_board' render={(props) => <Fieldboard list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_fields' render={(props) => <FarmFields list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_batch_pay_fields' render={(props) => <BatchPayFields list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_light_record/:bis_id/:id' render={(props) => <LightEntry list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_farm_ladger/:bis_id/:id' render={(props) => <FarmLadger list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_farm_record/:bis_id/:id' render={(props) => <FarmRecord list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_batch_account/:bis_id' render={(props) => <BatchAccounts list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_bird_sell/:bis_id/:id/:inv_no/:inv_id' render={(props) => <BirdSell list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_sell_report/:bis_id/:id/:inv_no/:inv_id' render={(props) => <SellReportField list={list} setList={setList} {...props} />} />
                        <FieldWorkerRoute exact path='/fwr_reset_pass' render={(props) => <ResetPass list={list} setList={setList} {...props} />} />

                        <SalesRepresentativeRoute exact path='/' component={Dashboard} />
                        <SalesRepresentativeRoute exact path='/sr_sells' render={(props) => <Sells setSupplierID={setSupplierID} SupplierID={SupplierID} list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute exact path='/sr_sell_reports' render={(props) => <Report list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute path='/sr_prv_sells_invoice/:inv_no' render={(props) => <PrvInvoice list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute path='/sr_farm_lists' render={(props) => <FarmLists list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute path='/sr_send_products/:id' render={(props) => <SendProduct list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute path='/sr_con_dispatch_report' render={(props) => <ContractDispatchReport list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute path='/sr_prv_dispatch_invoice/:inv_no' render={(props) => <PrvConInvoice list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute exact path='/sr_stock' render={(props) => <Stock setSupplierID={setSupplierID} SupplierID={SupplierID} list={list} setList={setList} {...props} />} />
                        <SalesRepresentativeRoute exact path='/sr_reset_pass' render={(props) => <ResetPass list={list} setList={setList} {...props} />} />


                        <AdminRoute exact path='/' component={Dashboard} />
                        {/* Business */}
                        <AdminRoute exact path='/bis_registration' render={(props) => <CreateCompany list={list} setList={setList} {...props} />} />
                        <AdminRoute exact path='/bis_lists' render={(props) => <BisList list={list} setList={setList} {...props} />} />
                        <AdminRoute exact path='/usr_list' render={(props) => <UserLists list={list} setList={setList} {...props} />} />

                        {/* Supplier */}
                        <AdminRoute exact path='/bis_supplier_reg' render={(props) => <SupplierReg list={list} setList={setList} {...props} />} />
                        <AdminRoute exact path='/bis_national_supplier_list' render={(props) => <NationalSuppliers list={list} setList={setList} {...props} />} />
                        <AdminRoute path='/adm_supplier_pro/:sup_id' render={(props) => <SupplierPro list={list} setList={setList} {...props} />} />
                        <AdminRoute exact path='/adm_product_item/:sup_id/:id' render={(props) => <ProductItems list={list} setList={setList} {...props} />} />
                        <AdminRoute exact path='/adm_reset_pass' render={(props) => <ResetPass list={list} setList={setList} {...props} />} />

                        <Route path='/not_found' component={NotFound} />
                        <Route component={NotFound} />
                        <Redirect path="/" exact to="/home" />
                    </Switch>
                </Layout>
            </Router>
        </Provider >
    );
}

export default App;