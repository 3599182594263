import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
// import posed, { PoseGroup } from "react-pose";
import SplitText from "react-pose-text";
// import "./index.css";

// const Modal = posed.div({
//     enter: {
//         y: 0,
//         opacity: 1,
//         beforeChildren: true,
//         staggerChildren: 60,
//         delay: 300,
//         transition: {
//             y: { type: "spring", stiffness: 1000, damping: 150 },
//             default: { duration: 500 }
//         }
//     },
//     exit: {
//         y: 50,
//         opacity: 0,
//         transition: { duration: 150 }
//     }
// });

// const Shade = posed.div({
//     enter: { opacity: 1 },
//     exit: { opacity: 0 },
//     transition: {
//         default: { duration: 300 }
//     }
// });

// const charPoses = {
//     exit: { opacity: 0, x: 20 },
//     enter: {
//         opacity: 1,
//         x: 0
//     }
// };

const Home = (data) => {
    const [isVisible, setisVisible] = useState(true)
    const [CurrentSlide, setCurrentSlide] = useState(0)
    const [SlideTimer, setSlideTimer] = useState(1 * 1000)
    const Slides = [
        "4 years of long journery since 2017",
        "Softapoul v6.00 ultimate solution for poultry business",
        "Enterprise Resource Planning (ERP) with Contract Farming"
    ]
    // setTimeout(function () { changeSlide(); }, 10000);
    useEffect(() => {
        setTimeout(() => {
            changeSlide();
        }, 8000);
    }, [CurrentSlide])


    const changeSlide = () => {
        let nextSlide = Slides.length - 1 > CurrentSlide ? CurrentSlide + 1 : 0;

        setisVisible(false)
        setCurrentSlide(nextSlide)
        setTimeout(function () { setisVisible(true) }, 500);
    }

    return (
        <div className="row h-100 m-0 d-flex justify-content-center"
        // onClick={() => changeSlide()}
        >
            <div className="header mb-4 bg-gradient maintxt align-items-center justify-content-center" style={{ maxHeight: "25vh", textShadow: "2px 1px 0px rgba(0, 0, 0, 0.1)" }}>
                <p className="display-2 d-flex justify-content-center text-success fw-normal" style={{ fontSize: "5vw" }}>Softa <p className="fw-bolder mr-1">Poul</p> v6.00</p>


                {/* <PoseGroup>
                    {isVisible && [
                        <Shade key="shade" className="shade" />,
                        <Modal key="modal" className="s_modal">
                            <p className="display-2 d-flex justify-content-center text-success fw-normal" style={{ fontSize: "5vw" }}>Softa <p className="fw-bolder mr-1">Poul</p> v6.00</p>
                            <h className="display-3 d-flex justify-content-center text-uppercase text-dark fw-bolder" style={{ fontSize: "2vw" }}>
                                <SplitText charPoses={charPoses}>
                                    {Slides[CurrentSlide]}
                                </SplitText>
                            </h>
                        </Modal>
                    ]}
                </PoseGroup> */}
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    data: state.auth.user,
});
export default connect(mapStateToProps)(Home);
