import "adminbsb-materialdesign/css/themes/all-themes.css";

import React, { Fragment, useEffect, useState } from "react";

import { GrBusinessService, GrGroup } from "react-icons/gr";
import { HiClipboardList } from "react-icons/hi";
import { Link } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { RiFileUserLine } from "react-icons/ri";
import { TiGroup } from "react-icons/ti";
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import { FaFileContract } from "react-icons/fa";
import Register from '../containers/AuthPages/Register';
// import Dashboard from '../containers/AuthPages/Dashboard';

const AdminSideBar = ({ logout, level, sub_level, user, children, setNavBar, NavBar, isActive, setActive }) => {
  const [Dropdown, setDropdown] = useState(null)


  return (
    <nav id="sidebar" className={NavBar ? "sidebar toggled" : "sidebar"}>

      <Link className="sidebar-brand p-0 mt-2" to="/">
        <img src={`${process.env.REACT_APP_API_URL}/Media/SoftaBin.png`} className="img-fluid mx-auto d-table" width="160" height="100" alt="avatar" />
        {/* SoftaPoul */}
      </Link>
      <div className="sidebar-content">
        <div className="sidebar-user pt-4">
          <img src={user ? process.env.REACT_APP_API_URL + user.Image : ''} className="img-fluid rounded-circle border border-success mb-0" width="50" height="55" alt="avatar" />
          <div className="fw-bold p-0 m-0 fs-4">{user ? user.FullName : ''}</div>
          <small className="text-muted">
            {
              level ? level === 9 ? "Super Admin" : level === 8 ? "Admin" : level === 7 ? "Representative" : null : null
            }
            {", SoftaPoul Administration"}
          </small>
          <br />
          <small>{user ? user.Email : ''}</small>
        </div>

        <ul className="sidebar-nav">
          <li className="sidebar-header">
            Main
          </li>

          {/* Dashboard */}
          <li className="sidebar-item">
            <a
              level-bs-target="#dashboards" level-bs-toggle="collapse"
              className={isActive >= 1 && isActive < 99 ? "sidebar-link active" : "sidebar-link collapsed"}
              aria-expanded={isActive >= 1 && isActive < 99 ? "true" : "false"}
              onClick={(e) => setActive(isActive >= 0 && isActive < 99 && isActive !== null ? null : 0)}>
              <i className="fad fa-tachometer-alt-average text-center align-middle fs-4 me-0" style={{ minWidth: "30px" }}></i> <span className="align-middle ml-2">Dashboards</span>
            </a>

            <ul id="dashboards" className={isActive < 99 && isActive !== null ? "sidebar-dropdown list-unstyled collapse show" : "sidebar-dropdown list-unstyled collapse"} level-bs-parent="#sidebar">
              <li className="sidebar-item" id="Dashboard" onClick={(e) => setActive(1)}>
                <Link className={isActive === 1 ? "sidebar-link active" : "sidebar-link"} to='/dashboard' >Default</Link>
              </li>

              <li className="sidebar-item" id="Analytics" onClick={(e) => { setActive(2) }}>
                <Link className={isActive === 2 ? "sidebar-link active" : "sidebar-link"} to='/' role='button'>Analytics</Link></li>
              <li className="sidebar-item" id="commerce" onClick={(e) => { setActive(3) }}>
                <Link className={isActive === 3 ? "sidebar-link active" : "sidebar-link"} to="/">Trend & Chart</Link></li>
            </ul>
          </li>

          <li className="sidebar-item">
            <a
              level-bs-target="#accounts" level-bs-toggle="collapse"
              className={isActive >= 901 && isActive < 999 ? "sidebar-link active" : "sidebar-link collapsed"}
              aria-expanded={isActive >= 901 && isActive < 999 ? "true" : "false"}
              onClick={(e) => setActive(isActive >= 900 && isActive < 999 && isActive !== null ? null : 900)}>
              <i className="fad fa-calculator-alt text-center align-middle fs-4 me-0" style={{ minWidth: "30px" }}></i>
              <span className="align-middle ml-2">Business</span>
            </a>

            <ul id="accounts" className={isActive >= 900 && isActive < 999 ? "sidebar-dropdown list-unstyled collapse show" : "sidebar-dropdown list-unstyled collapse"} level-bs-parent="#sidebar">
              <li className="sidebar-item" id="Debit" onClick={(e) => setActive(901)}>
                <Link className={isActive === 901 ? "sidebar-link active" : "sidebar-link"} to='/bis_registration' >Business Register</Link>
              </li>
              <li className="sidebar-item" id="Debit" onClick={(e) => setActive(905)}>
                <Link className={isActive === 905 ? "sidebar-link active" : "sidebar-link"} to='/bis_lists' >Business Profiles</Link>
              </li>
            </ul>
          </li>

          {/* Suppliers */}
          <li className="sidebar-item">
            <a
              level-bs-target="#dashboards" level-bs-toggle="collapse"
              className={isActive >= 301 && isActive < 399 ? "sidebar-link active" : "sidebar-link collapsed"}
              aria-expanded={isActive >= 301 && isActive < 399 ? "true" : "false"}
              onClick={(e) => setActive(isActive >= 300 && isActive < 399 && isActive !== null ? null : 300)}>
              <i class="fad fa-briefcase text-center align-middle fs-4 me-0" style={{ minWidth: "30px" }}></i> <span className="align-middle ml-2">Suppliers</span>
            </a>

            <ul id="Suppliers" className={isActive >= 300 && isActive < 399 ? "sidebar-dropdown list-unstyled collapse show" : "sidebar-dropdown list-unstyled collapse"} level-bs-parent="#sidebar">

              <li className="sidebar-item">
                <Link className={isActive === 301 ? "sidebar-link active" : "sidebar-link"} to='/bis_supplier_reg' id="SupplierReg" role='button' onClick={(e) => setActive(301)}>Add New Supplier</Link>
              </li>

              <li className="sidebar-item">
                <Link className={isActive === 304 ? "sidebar-link active" : "sidebar-link"} to='/bis_national_supplier_list' id="AllSuppliers" role='button' onClick={(e) => setActive(304)}>National Suppliers</Link>
              </li>


            </ul>
          </li>



          <li className="sidebar-item" id="Profiles" onClick={(e) => setActive(500)}>
            <Link className={isActive === 500 ? "sidebar-link active" : "sidebar-link"} to='/usr_list'>
              <i class="fad fa-users text-center align-middle fs-4 me-0" style={{ minWidth: "30px" }}></i> <span className="align-middle ml-2">Users</span></Link>
          </li>

          {level === 6 || (level === 3 && (sub_level === 7 || sub_level === 8 || sub_level === 9 || sub_level === 10)) ?
            <Fragment>
              {/* Profile */}


              {/* Register */}
              <li className="sidebar-item">
                <a
                  level-bs-target="#dashboards" level-bs-toggle="collapse"
                  className={isActive >= 201 && isActive < 299 ? "sidebar-link active" : "sidebar-link collapsed"}
                  aria-expanded={isActive >= 201 && isActive < 299 ? "true" : "false"}
                  onClick={(e) => setActive(isActive >= 200 && isActive < 299 && isActive !== null ? null : 200)}>
                  <i class="fad fa-file-user text-center align-middle fs-4 me-0" style={{ minWidth: "30px" }}></i><span className="align-middle ml-2">User Register</span>
                </a>
                <ul id="UserRegister" className={isActive >= 200 && isActive < 299 ? "sidebar-dropdown list-unstyled collapse show" : "sidebar-dropdown list-unstyled collapse"} level-bs-parent="#sidebar">
                  {/* Level is SuperAdmin */}
                  {level === 9 ?
                    <li className="sidebar-item">
                      <Link className={isActive === 201 ? "sidebar-link active" : "sidebar-link"} to='/create_company' id="CreateCompany" role='button' onClick={(e) => { setActive(201) }}>Add New Company</Link>
                    </li> : null}

                  <li className="sidebar-item">
                    <Link className={isActive === 202 ? "sidebar-link active" : "sidebar-link"} to='/Register' id="Register" role='button' onClick={(e) => { setActive(202) }}>Add New User</Link>
                  </li>
                  {/* Level is CEO */}
                  {level === 6 ?
                    <li className="sidebar-item">
                      <Link className={isActive === 203 ? "sidebar-link active" : "sidebar-link"} to='/create_branch' id="Create_Branch" role='button' onClick={(e) => { setActive(203) }}>Create Branch</Link>
                    </li> : null
                  }

                  <li className="sidebar-item">
                    <Link className={isActive === 204 ? "sidebar-link active" : "sidebar-link"} to='/LoadPending' id="LoadPending" role='button' onClick={(e) => { setActive(204) }}>Pending User</Link>
                  </li>
                </ul>
              </li>
            </Fragment>
            :
            null}
          {level === 6 ?
            <Fragment>
              <li className="sidebar-item" id="BProfiles" onClick={(e) => setActive(800)}>
                <Link className={isActive === 800 ? "sidebar-link active" : "sidebar-link"} to='/business_profiles'>
                  <i class="fad fa-globe text-center align-middle fs-4 me-0" style={{ minWidth: "30px" }}></i> <span className="align-middle ml-2">Business Profile</span></Link>
              </li>

              {/* Business Preference */}

              <li className="sidebar-item">
                <a
                  level-bs-target="#dashboards" level-bs-toggle="collapse"
                  className={isActive >= 1101 && isActive < 1199 ? "sidebar-link active" : "sidebar-link collapsed"}
                  aria-expanded={isActive >= 1101 && isActive < 1199 ? "true" : "false"}
                  onClick={(e) => setActive(isActive >= 1100 && isActive < 1199 && isActive !== null ? null : 1100)}>
                  <i class="fad fa-cogs text-center align-middle fs-4 me-0" style={{ minWidth: "30px" }}></i>
                  <span className="align-middle ml-2">Business Preference</span>
                </a>

                <ul id="Inventory" className={isActive >= 1100 && isActive < 1199 ? "sidebar-dropdown list-unstyled collapse show" : "sidebar-dropdown list-unstyled collapse"} level-bs-parent="#sidebar">

                  <li className="sidebar-item">
                    <Link className={isActive === 1101 ? "sidebar-link active" : "sidebar-link"} to='/acc_settings' id="InvPurchase" role='button' onClick={(e) => setActive(1101)}>Account Settings</Link>
                  </li>

                  <li className="sidebar-item">
                    <Link className={isActive === 1102 ? "sidebar-link active" : "sidebar-link"} to='/profit_margin' id="ProductStock" role='button' onClick={(e) => setActive(1102)}>Profit Margin</Link>
                  </li>

                  <li className="sidebar-item">
                    <Link className={isActive === 1103 ? "sidebar-link active" : "sidebar-link"} to='/policy' id="Reports" role='button' onClick={(e) => setActive(1103)}>Contract Farming Policy</Link>
                  </li>


                </ul>
              </li>
            </Fragment>
            : null
          }

        </ul>
      </div >
    </nav >
  );

}

const mapStateToProps = state => ({
  user: state.auth.user,
  level: state.auth.level,
  sub_level: state.auth.sub_level
});

export default connect(mapStateToProps, { logout })(AdminSideBar);
