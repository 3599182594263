import React, { useState, useEffect, Fragment } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import { FetchAllFarmPayment, LoadMyFarms, LoadPayment } from '../../../actions/ContractAPI';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../actions/types';
import Select from 'react-select';
import { findUnique } from '../../../actions/APIHandler';

const PaymentList = ({ display }) => {
    const [Data, setData] = useState(null)
    const [View, setView] = useState(false)
    const dispatch = useDispatch();
    const [SearchKey, setSearchKey] = useState('')

    useEffect(() => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        // MyFarms();
        GetPaymentList();
    }, [])

    const MyFarms = async () => {
        var result = await LoadMyFarms();
        setData(result.farms);

        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const GetPaymentList = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var result = await FetchAllFarmPayment();


        if (result !== true)
            setData(result.data);
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const history = useHistory();

    const CScolourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: "2px solid #FFFFFF", boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", borderRadius: '20px' }),
        container: base => ({
            ...base,
            flex: 1,
        }),
    }

    let unique_search = Array.isArray(Data) && Data.length ? findUnique(Data, d => d.Title) : null;
    let FilterFarms = Array.isArray(Data) && Data.length ? Data.filter(function (item) {
        let BothValue = SearchKey ? item.BatchID === SearchKey.value : true;

        return BothValue
    }).map(function ({ id, BranchID, BranchName, BatchID, BatchNo, UserID, Image, FarmID, Title, FullName, Status, Account }) {
        return { id, BranchID, BranchName, BatchID, BatchNo, UserID, Image, FarmID, Title, FullName, Status, Account };
    }) : null


    return (
        <div className="row h-100 m-0 d-flex justify-content-center">

            <div className="header mb-4">
                <p className="display-6 d-flex justify-content-center m-0">
                    Farmer Payment List
                </p>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb d-flex justify-content-center">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/supplier_list">Accounting</Link></li>
                        <li className="breadcrumb-item"><Link to="/supplier_list">Farmer Payment</Link></li>
                    </ol>
                </nav>
            </div>

            <div className="col-lg-8 h-100 pl-0">
                <div div className="row d-flex bg-white mx-auto" >
                    <div className="d-flex justify-content-between p-0">

                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <button className="btn rounded-0 rounded-start border-right d-flex align-items-center" id="list">
                                <i class="fad fs-3 fa-qrcode text-dark"></i>
                            </button>
                        </div>

                        <div className="d-flex justify-content-center mx-2 w-100">
                            <Select
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                borderRadius={"0px"}
                                options={Array.isArray(unique_search) && unique_search.length ? unique_search.map((item) => ({ label: item.FarmID + ". " + item.Title, value: item.BatchID })) : []}
                                defaultValue={{ label: "Select Dept", value: 0 }}
                                name="Division"
                                placeholder={"Search"}
                                styles={CScolourStyles}
                                value={SearchKey}
                                onChange={(e) => setSearchKey(e)}
                                required
                                id="Title"
                                isClearable={true}
                                isSearchable={true}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button className="btn rounded-0 rounded-end border-left d-flex align-items-center" id="grid" title="View"
                                onClick={() => setView(View ? false : true)}>
                                <i class={`fad fs-3 text-dark ${View ? "fa-th-list" : "fa-th"}`}></i>
                            </button>
                        </div>

                    </div>
                </div>


                <div className="row position-absolute overflow-auto mx-auto mt-3 w-100">

                    <div id="products" className="row view-group m-0 p-0">
                        {
                            Array.isArray(FilterFarms) && FilterFarms.length ? FilterFarms.map((item, i) => (
                                <div className={View ? "item col-xs-3 col-lg-3 grid-group-item mb-3" : "item col-xs-3 col-lg-3 grid-group-item list-group-item mb-3"} key={i}>
                                    {/* <Link to={`/sells/${item.details.UserID}`} */}
                                    <Link Title="Click to make invoice" to={`/send_products/${item.FarmID}`}
                                        className="box thumbnail card py-2 shadow-none m-0 h-100">

                                        <div className="img-event d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "10vh" }}>
                                            <img
                                                src={item.Image ? process.env.REACT_APP_API_URL + "/Media/" + item.Image : process.env.REACT_APP_API_URL + "/Media/no_logo.jpeg"}
                                                className="img-fluid" alt="avatar"
                                                style={{ minWidth: "15vh" }} width="20px" height="20px" />
                                        </div>

                                        <div className="caption card-body d-flex flex-column justify-content-center py-0 px-2" style={{ minHeight: "15vh" }}>
                                            <p className="group inner list-group-item-text m-0">
                                                <small className="group card-title inner list-group-item-text fs-4 fw-bold px-1">
                                                    <Link Title="Go to farms to view active batch" to={`/farm_mng/${item.UserID}/${item.FarmID}/${item.BatchID}`}>{item.FarmID + ". " + item.Title}</Link>
                                                </small>
                                                <br />
                                                <small className='d-flex bg-light px-1'>
                                                    <span className="text-muted fw-bold">
                                                        Batch No- {item.BatchNo}, &nbsp; Batch ID- {item.BatchID}, &nbsp;
                                                    </span>
                                                    <Link Title="Go to user profiles" to={`/user_profile/${item.UserID}`}>{item.UserID + ". " + item.FullName}</Link>
                                                    <span className="text-muted fw-bold px-1">
                                                        &nbsp; {item.BranchID + ". " + item.BranchName + " Branch"}
                                                    </span>
                                                </small>
                                                {/* <small className="text-muted">
                                                    {"H#" + item.details.HoldingNo + ", Word No- " + item.details.WardNo + ", Postal Code- " + item.details.PostalCode}<br />
                                                    {item.details.VillageName + ", " + item.details.Union + ", " + item.details.Upazila + ", " + item.details.Zila + ", " + item.details.Division}
                                                </small> */}

                                            </p>
                                            <div className="px-1 border-bottom">
                                                {
                                                    Array.isArray(item.Account) && item.Account.length ? item.Account.map((acc, i) => (
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className='m-0'> {acc.AccountTitle}</p>
                                                            <p className='m-0'> {parseInt(acc.DR).toLocaleString("en", { minimumFractionDigits: 2 })}</p>
                                                            <p className='m-0'> {parseInt(acc.CR).toLocaleString("en", { minimumFractionDigits: 2 })}</p>
                                                        </div>
                                                    ))
                                                        : null
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center px-1 my-1">
                                                <p className='m-0 border border-warning text-warning px-2' style={{ borderRadius: "15px" }}>{item.Status === 0 ? "Batch Closed" : item.Status === 1 ? "Active" : item.Status === 2 ? "Request for batch review" : item.Status === 3 ? "Waiting for accounts approval" : item.Status === 4 ? "Paid" : item.Status === 5 ? "Payment hold" : null}</p>
                                                <Link to={`/farmer_payment_release/${item.BatchID}`} className='btn btn-outline-danger fw-bold m-0 py-0' style={{ borderRadius: "15px" }}>Proceed</Link>
                                            </div>
                                        </div>

                                    </Link>

                                </div>

                            )) :
                                <div className={`d-flex justify-content-center align-items-center bg-white`}>
                                    <h className='fs-4 fw-bold text-success'>No payment found!</h>
                                </div>
                        }

                    </div>
                </div>
            </div >
        </div>
    );
}

const mapStateToProps = state => ({
    display: state.OverlayDisplay
});

export default connect(mapStateToProps, { logout })(PaymentList);