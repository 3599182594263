import React, { useState, useEffect, Fragment } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../actions/auth';
import { AccJournal, DeleteJournal } from '../../actions/APIHandler';
import { AllProductList } from '../../actions/APIHandler';
import { GetFarm, Invoice } from '../../actions/ContractAPI';
import { connect, useDispatch } from 'react-redux';
import * as moment from 'moment'
import { DISPLAY_OVERLAY } from '../../actions/types';
import { InfoMessage } from "../Modals/ModalForm.js";
import Select from 'react-select';
import errorIcon from '../../assets/error.png';
import infoIcon from '../../assets/info.png';
import successIcon from '../../assets/success.png';
import warningIcon from '../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { ViewModal } from './Modal/ViewModal';
import { CreateModal } from './Modal/CreateModal';
import { UpdateModal } from './Modal/UpdateModal';
import { DeleteMessage } from './Modal/DeleteModal';
import COA from './COA';


const AccountJournal = ({ display, BisID, CompanyID, BranchID, user, list, setList }) => {
    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [ViewModalShow, setViewModalShow] = useState(false)
    const [DeleteModalShow, setDeleteModalShow] = useState(false)

    const [Journal, setJournal] = useState()
    const [ItemID, setItemID] = useState()

    let toastProperties = null;
    const dispatch = useDispatch();


    useEffect(() => {
        LoadJournal();
        // LoadProductItems();
    }, [])

    const LoadJournal = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var result = await AccJournal();

        if (result !== true) {
            setJournal(result);
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
            history.push('/farm_lists');
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const CScolourStyles = {
        container: base => ({
            ...base,
            flex: 1,
        }),
    }

    const history = useHistory();

    const RemoveJournal = async e => {
        setDeleteModalShow(false)
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        e.preventDefault();;
        const result = await DeleteJournal(ItemID.id);
        if (result !== true) {
            LoadJournal();
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    };

    return (
        <div className="row h-100 m-0 d-flex justify-content-center">
            <div className="header mb-2">
                <p className="display-6 d-flex justify-content-center m-0">Account Journal</p>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb d-flex justify-content-center" m-0>
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/acc_journal">Account Journal</Link></li>
                    </ol>
                </nav>
            </div>
            <div className="col-lg-12 h-100 p-0">
                <div className="position-absolute overflow-auto my-1 w-100 bg-white" style={{ maxHeight: "90%" }}>
                    <table className={`table table-borderless table-responsive card-1 d-table`}>
                        <thead>
                            <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                <th className="border-right py-2"><span className="fs-6 fw-bolder text-uppercase">Date</span></th>
                                <th className="border-right py-2"><span className="fs-6 fw-bolder text-uppercase">Account Title & Description</span></th>
                                <th className="border-right py-2"><span className="fs-6 fw-bolder text-uppercase">Ref</span></th>
                                <th className="border-right py-2"><span className="fs-6 fw-bolder text-uppercase">Debit</span></th>
                                <th className="border-right py-2"><span className="fs-6 fw-bolder text-uppercase">Credit</span></th>
                                <th className="border-0 py-2"><span className="fs-6 fw-bolder text-uppercase">Action</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(Journal) && Journal.length ? Journal.map((item, i) => (
                                    Array.isArray(item.voucher_map) && item.voucher_map.length ? item.voucher_map.map((data, n) => (
                                        <>
                                            {/* <tr className={`text-center border-top ${parseInt(data.CR) === 0 ? '' : 'bg-body bg-gradient'}`} key={i}> */}
                                            <tr className={`text-center border-top`} key={i}>
                                                {
                                                    parseInt(data.SLNo) === 1 ?
                                                        <td rowSpan={parseInt(item.Count) + 1} className="border-right p-0">
                                                            <span className="d-block fs-4 fw-bolder text-center">{moment(item.CreatedAt).format("DD MMM YYYY")}</span>
                                                            <samll className="fw-normal text-muted"> {moment(data.UpdatedAt).format("hh.mm.s A")}</samll>
                                                            <br />
                                                            <samll className="fw-normal"> {item.UpdatedBy}</samll>
                                                        </td>
                                                        : null
                                                }
                                                <Fragment>
                                                    <td className={`border-right py-0 ${parseInt(data.DR) === 0 ? 'pl-4' : 'px-2'}`}><span className="d-block fs-6 fw-bold text-left">{data.COA.COA_Title}</span></td>
                                                    {/* {data.COA ?
                                                    <td className={`border-right py-0 ${parseInt(data.DR) === 0 ? 'pl-5' : 'px-2'}`}><span className="d-block fs-6 fw-bold text-left">{data.COA.COA_Title + "" + data.SupplierID ? data.SupplierID.CmpName : data.BatchID.id}</span></td>
                                                    :
                                                    <td className={`border-right py-0 ${parseInt(data.CR) === 0 ? 'px-2' : 'pl-5'}`}><span className="d-block fs-6 fw-bold text-left">{data.BusinessID.Title}</span></td>
                                                } */}
                                                    <td className="border-right p-0"><Link className="d-block fs-6 fw-bold text-center" to="#">{data.COA ? data.COA.COA_Code : data.BatchID.BusinessID.id}</Link></td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-center">{parseInt(data.DR) === 0 ? "—" : parseInt(data.DR).toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-center">{parseInt(data.CR) === 0 ? "—" : parseInt(data.CR).toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>

                                                    {
                                                        parseInt(data.SLNo) === 1 ?
                                                            <td rowSpan={parseInt(item.Count) + 1} className="p-0 mb-2">
                                                                <button className="btn p-1 text-dark"
                                                                    onClick={() => { setItemID(item); setDeleteModalShow(true) }} ><i className="fs-3 fad fa-trash-alt" />
                                                                </button>
                                                            </td> : null}
                                                </Fragment>
                                            </tr>
                                            {
                                                parseInt(data.SLNo) === parseInt(item.Count) ?
                                                    <>
                                                        <tr className="text-center border-top border-bottom bg-body bg-gradient rounded">
                                                            <td colspan="4" className="border-right text-left p-0">
                                                                <span className="fs-6 fw-bold text-primary text-left px-2">({item.Narration}</span><span className="fs-6 fw-bold text-primary text-left px-2">,{data.BusinessID !== null ? data.BusinessID.Title : data.BatchID !== null ? data.BatchID.BusinessID.Title : data.SupplierID !== null ? data.SupplierID.CmpName : null})</span>
                                                            </td>
                                                        </tr>
                                                        <tr height="15px" />
                                                    </>
                                                    : null
                                            }

                                        </>
                                    ))
                                        : null

                                ))
                                    :
                                    null
                            }
                        </tbody >
                    </table >
                </div >
            </div >
            {
                ItemID ?
                    <DeleteMessage
                        FullName={ItemID.Narration}
                        show={DeleteModalShow}
                        Click={(e) => RemoveJournal(e)}
                        onHide={() => setDeleteModalShow(false)}
                    />
                    : null
            }
        </div >

    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    BisID: props.match.params.id
});

export default connect(mapStateToProps, { logout })(AccountJournal);