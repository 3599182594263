import * as moment from 'moment'
import { LoadBatchAccount, DeleteDR, SaveBatchAcc, LoadBatchAssesment } from '../../../actions/ContractAPI';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { checkToken, logout } from '../../../actions/auth';
import { connect, useDispatch } from 'react-redux';

import { CreateMessage, InfoMessage } from "../../Modals/ModalForm.js";
import { DISPLAY_OVERLAY } from '../../../actions/types';
import { DeleteMessage } from '../../Modals/DeleteModal';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';
import { exportPDF } from '../../Suppliers/Class/OrderPDF';

const BatchAssesment = ({ UserID, BisID, BatchID, user, list, setList, level, sub_level, Status }) => {

    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const [ItemID, setItemID] = useState();

    const [InfoModalShow, setInfoModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [BankLists, setBankLists] = useState(initialValue)
    const [BBLists, setBBLists] = useState(initialValue)
    const [Data, setData] = useState(false)
    const [Error, setError] = useState([])
    const [Stock, setStock] = useState(false)
    const [CheckAll, setCheckAll] = useState(true)
    const [Amount, setAmount] = useState(0.00);
    const [Expand, setExpand] = useState(false);
    const [AccordLbl, setAccordLbl] = useState("Add New Product");
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();
    let { path, url } = useRouteMatch();
    useEffect(() => {
        FetchBatchAssesment(BisID);
        // handleCheckAll(Data);
    }, [])

    const FetchBatchAssesment = async () => {
        if ((level === 3 && (sub_level === 5 || sub_level === 9 || sub_level === 10)) || (level === 6 && sub_level)) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var result = await LoadBatchAssesment(BisID, 2);


            if (result !== true) {
                setData(result.data.acc_items);
                setStock(result.data.Stock);
            }

            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            history.push(url);
        }
    }

    // const handleKeyDown = (e) => {
    //     if (e.keyCode === 13) {
    //         onSubmit();
    //     }
    // }

    // const getTotal = () => {
    //     let TotalPrice = 0;
    //     const price = OrderData.map(row => row.Quantity * row.UnitPrice);
    //     if (price.length > 0) {
    //         TotalPrice = price.reduce((acc, val) => acc + val);
    //     }
    //     return TotalPrice;
    // }
    // const QuantityTotal = OrderData.reduce((TotalQuantity, myvalue) => TotalQuantity + parseInt(myvalue.Quantity, 10), 0);

    // const deleteRow = (i) => {
    //     // make new rows. note: react state is immutable.
    //     const newRows = OrderData.splice(i, 1).concat(OrderData.slice(i + 1));
    //     // setRows({ OrderData: newRows })
    //     setCount(Count - 1)
    // };

    // const onSubmit = (e) => {
    //     if (formData.Quantity === "" || formData.Quantity === undefined || formData.ItemCode === "" || formData.ItemCode === undefined || formData.UnitPrice === "" || formData.UnitPrice === undefined) {
    //         setInfoModalShow(true)
    //     } else {
    //         setOrderData([...OrderData, formData]);
    //         setCount(Count + 1);
    //         setFormData({
    //             Title: "",
    //             UnitWeight: "",
    //             UnitPrice: "",
    //             Quantity: "",
    //             ItemCode: "",
    //         });
    //     }
    // }

    const today = new Date().toLocaleDateString("en-us", "dd/MM/yyyy");

    const RemoveDR = async e => {
        setDeleteModalShow(false)
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        e.preventDefault();

        const result = await DeleteDR(ItemID.id);
        if (result !== true) {
            window.location.reload(false)
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    };

    const CheckedAll = () => {
        setCheckAll(CheckAll ? false : true)
        setData(prevValue => [...prevValue].map(el => ({ ...el, Status: 1 })))
    }

    const Checked = (id, Data) => {
        setData(prevValue => [...prevValue].map(el => el.SLNo === id ? ({ ...el, Status: el.Status ? 0 : 1 }) : el))
        setCheckAll(false);
    }

    const handleCheckAll = (Data) => {
        // const Status = Data.reduce((TotalStatus, myvalue) => TotalStatus + parseInt(myvalue.Status, 10), 0);
        // if (Data.length - 3 === Status) {
        //     setCheckAll(true);
        // } else {
        //     setCheckAll(false);
        // }

    }




    const getSumColumn = (arr, index) => {
        let sum = 0
        arr.forEach((el, i) => i <= index ? sum += el.DR - el.CR : 0.00)
        // bal(sum, index)
        return sum;
    }

    // const bal = (value, index) => setData(prevValue => [...prevValue].map((el, i) => i === index ? ({ ...el, Balance: value }) : el))
    // arr.forEach((el, i) => i <= 1 ? sum += el[6] - el[5] : el)
    // 

    const SendBatchAc = async (Data) => {
        const result = await SaveBatchAcc(Data, BatchID);

        if (result !== true) {
            if (result.user_error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Invalid',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }])

            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Success',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                setData(false);
                FetchBatchAssesment();
            }
        } else {
            setList([...list, toastProperties = {
                id: 1,
                title: 'Error',
                description: "Payment request failed. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
        }
    }

    return (
        <div className="position-relative mb-5" style={{ height: "92%" }}>

            <div className="position-absolute overflow-auto my-1 w-100 h-75 bg-white">

                <div className="d-flex justify-content-center bg-white py-2">
                    <div className="col-md-12 justify-content-center align-items-center">
                        {
                            Array.isArray(Data) && Data.length ?
                                <table className={`table table-hover`} style={{ maxHeight: "70%" }}>
                                    <thead>
                                        <tr className="text-center">
                                            <th className="p-1 border-0" colspan="14">
                                                <div className="d-flex">
                                                    <div className="mr-auto"></div>
                                                    <p className="mr-auto p-0 fs-4 fw-bolder mb-0 text-center text-uppercase">Batch Assesment</p>
                                                    <p className="fs-4 fw-bolder p-0 mb-0"><i class="fad fa-layer-plus"></i></p>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                            <th className="border-right py-1 align-middle"><span className="d-flex justify-content-center align-items-center">
                                                <input
                                                    class="form-check-input m-0"
                                                    type="checkbox"
                                                    value=""
                                                    checked={CheckAll ? true : false}
                                                    id="flexCheckDefault"
                                                    onChange={(e) => CheckedAll()}
                                                />
                                            </span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">SLNo</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">ID</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Title</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Quantity</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Weight</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">#</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Rate</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Debit</span></th>
                                            <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Credit</span></th>
                                            <th className="border-top py-2 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Balance</span></th>
                                            {/* <th className="p-0 align-middle"><span className="fs-6 fw-bolder text-dark">Action</span></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Data.map((item, i) => (
                                            parseInt(item.OpCode) === 18 || parseInt(item.OpCode) === 19 || parseInt(item.OpCode) === 20 ?
                                                <tr className="border-bottom text-center" key={i}>
                                                    <td colSpan={10} className="border-right p-0"><span className="d-block fs-6 fw-bolder text-right text-success text-uppercase p-1">
                                                        {parseInt(item.OpCode) === 18 ? "Payment" : parseInt(item.OpCode) === 19 ? "Net Payment" : parseInt(item.OpCode) === 20 ? "Grand Payment" : null}
                                                    </span></td>
                                                    <td className="border-0 p-0"><span className="d-block fs-6 fw-bolder text-success text-right text-uppercase px-2" style={{ borderBottomStyle: parseInt(item.OpCode) === 20 ? "double" : null }}>{getSumColumn(Data, i) ? getSumColumn(Data, i).toLocaleString("en-BD", { minimumFractionDigits: 2 }) + " ৳" : "—"}</span> </td>

                                                </tr> :

                                                <tr className="border-bottom text-center" key={i}>
                                                    <td className="border-right p-0"><span className="d-flex justify-content-center align-items-center">
                                                        <input
                                                            class="form-check-input m-0"
                                                            type="checkbox"
                                                            checked={item.Status ? true : false}
                                                            id="flexCheckDefault"
                                                            onChange={(e) => Checked(item.SLNo, Data)}
                                                        />
                                                    </span></td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-center text-dark">{i}</span></td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-center text-dark btn px-2" >{item.SLNo}</span></td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-left text-dark px-2">{item.Title}</span></td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{(item.Qty).toLocaleString("en-BD", { minimumFractionDigits: 0 })} PCS</span> </td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-right text-dark px-2">{(item.Weight).toLocaleString("en-BD", { minimumFractionDigits: 2 })} KG</span> </td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-right text-dark px-2">{(item.Point).toLocaleString("en-BD", { minimumFractionDigits: 2 })}</span> </td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-right text-dark px-2">{(item.Rate).toLocaleString("en-BD", { minimumFractionDigits: 2 })} ৳</span> </td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-right text-dark px-2">{item.DR ? (item.DR).toLocaleString("en-BD", { minimumFractionDigits: 2 }) + " ৳" : "—"}</span> </td>
                                                    <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-right text-dark px-2">{item.CR ? (item.CR).toLocaleString("en-BD", { minimumFractionDigits: 2 }) + " ৳" : "—"}</span> </td>
                                                    {/* <td className="border-0 p-0"><span className="d-block fs-6 fw-bold text-right text-dark px-2">{getSumColumn(Data, i) ? getSumColumn(Data, i).toLocaleString("en-BD", { minimumFractionDigits: 2 }) + " ৳" : "—"}</span> </td> */}
                                                    <td className="border-0 p-0"><span className="d-block fs-6 fw-bold text-right text-dark px-2">{item.Balance ? (item.Balance).toLocaleString("en-BD", { minimumFractionDigits: 2 }) + " ৳" : "—"}</span> </td>
                                                    {/* <td className="border-0 p-0">
                                                    <button className="btn p-1 text-center text-dark" onClick={() => {
                                                        getSumColumn(Data, i)
                                                    }} ><i className="fs-6 fad fa-trash-alt" /></button>
                                                </td> */}
                                                </tr>

                                        ))}
                                    </tbody>

                                </table>
                                : null
                        }
                        {
                            (level === 3 && (sub_level === 5 || sub_level === 9 || sub_level === 10)) ?
                                <div className='d-flex justify-content-center align-items-center'>

                                    <i className="fs-1 fad fa-paper-plane p-3 text-danger border-left border-top border-bottom border-danger bg-gradient bg-white" style={{ borderRadius: "35px", zIndex: "999", marginRight: "-10px" }} />
                                    {
                                        Status === 0 ? <span class="fs-5 text-white px-3 bg-success fw-bold">Batch Closed</span>
                                            : Status === 1 ? <span class="fs-5 text-white px-3 bg-success fw-bold">Active (Batch is not dispatch yet)</span>
                                                : Status === 2 ?
                                                    <Link to="#" className="payment_btn m-0 px-0 text-decoration-none link-white" Title="Request for payment to accounts"
                                                        onClick={() => SendBatchAc(Data)}>
                                                        <span class="payment_btn_inner ml-3">Request for payment</span>
                                                    </Link>
                                                    : Status === 3 ? <span class="fs-5 text-white px-3 bg-success fw-bold">Waiting for accounts approval</span>
                                                        : Status === 4 ? <span class="fs-5 text-white px-3 bg-success fw-bold">Paid</span> : Status === 5 ? <span class="fs-5 text-white px-3 bg-success fw-bold">Payment Hold (Need to full review)</span>
                                                            : null}
                                </div>
                                :
                                null}
                    </div>

                </div>
            </div >
            {
                ItemID ?
                    <DeleteMessage
                        FullName={`${ItemID.Date} dated mortality ${ItemID.DMort} & Consumption ${ItemID.DCons} with feed sent ${ItemID.DFeed}?`}
                        show={DeleteModalShow}
                        Click={(e) => RemoveDR(e)}
                        onHide={() => setDeleteModalShow(false)}
                    />
                    : null
            }
        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
    level: state.auth.level,
    sub_level: state.auth.sub_level,
});

export default connect(mapStateToProps, { logout })(BatchAssesment);