import React, { useState, useEffect, Fragment } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import { FetchLiveStock, ParkBatch } from '../../../actions/ContractAPI';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../actions/types';
import * as moment from 'moment'
import Select from 'react-select';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';

import { exportPDF } from '../../Suppliers/Class/OrderPDF';
import { Search } from 'semantic-ui-react';

import { SellModal, InfoMessage } from "./Modals/ModalForm.js";
let today = new Date();
const LiveStock = ({ CompanyID, BranchID, SupplierID, user, list, setList }) => {
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [SellModalShow, setSellModalShow] = useState(false);
    const [InfoModalShow, setInfoModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [Data, setData] = useState(false)
    const [StockItem, setStockItem] = useState(false)
    const [OrderData, setOrderData] = useState([])
    const [Count, setCount] = useState(null)
    const [SearchKey, setSearchKey] = useState(false)
    const [Error, setError] = useState({});
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        LoadLiveStock();
    }, [])

    const LoadLiveStock = async () => {
        if (user !== null) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var result = await FetchLiveStock();

            if (result.status === 200)
                setData(result.data);
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            history.push('/');
        }
    }

    const BatchPark = async e => {
        if (!StockItem) {
            setSellModalShow(false)
        } else {
            setSellModalShow(false)
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            e.preventDefault();
            const result = await ParkBatch(StockItem.id, StockItem.Sell ? 0 : 1);

            if (result !== true) {
                if (result.error) {
                    const updatedState = {};
                    for (var pair of result.exception.entries()) {
                        updatedState[pair[1].field] = pair[1].message;
                        setError({
                            ...updatedState,
                        });
                    }
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Invalid Data',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: warningIcon
                    }])
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                } else {
                    setList([...list, toastProperties = {
                        id: 1,
                        title: result.Title,
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: result.ico === 1 ? infoIcon : successIcon
                    }])
                    LoadLiveStock();
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                }
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Error',
                    description: "Failed to parked the batch. Please try after some moment.",
                    backgroundColor: '#f0ad4e',
                    icon: errorIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }

            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        }
    };

    const getTotal = () => {
        let TotalPrice = 0;
        const price = OrderData.map(row => row.Quantity * row.UnitPrice);
        if (price.length > 0) {
            TotalPrice = price.reduce((acc, val) => acc + val);
        }
        return TotalPrice;
    }
    const QuantityTotal = OrderData.reduce((TotalQuantity, myvalue) => TotalQuantity + parseInt(myvalue.Quantity, 10), 0);

    const deleteRow = (i) => {
        // make new rows. note: react state is immutable.
        const newRows = OrderData.splice(i, 1).concat(OrderData.slice(i + 1));
        // setRows({ OrderData: newRows })
        setCount(Count - 1)
    };

    const CScolourStyles = {
        container: base => ({
            ...base,
            flex: 1,
        }),
    }

    const CalculateAge = (DOB) => {
        let today = new Date();
        let BirthDate = new Date(DOB);
        let tod = today.getTime();
        let days_diff = Math.ceil(Math.abs(tod - BirthDate) / (1000 * 60 * 60 * 24));
        let age = "Age " + days_diff + " Days";
        return age;
    }

    const findArrayElementByTitle = (Data, SearchKey) => {
        return Data.find((element) => {
            return element.ItemCode.Title === SearchKey;
        })
    }

    const BirdStatus = (value) => {
        if (value === 100)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-dark px-3 py-0" style={{ borderRadius: "15px" }}>Parked</span>
            )
        else if (value === 1)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-secondary px-3 py-0" style={{ borderRadius: "15px" }}>Boarding Period</span>
            )
        else if (value === 2)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-primary px-3 py-0" style={{ borderRadius: "15px" }}>Growing Period</span>
            )
        else if (value === 3)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-warning px-3 py-0" style={{ borderRadius: "15px" }}>Matured</span>
            )
        else if (value === 4)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-danger px-3 py-0" style={{ borderRadius: "15px" }}>Ready To Sell</span>
            )
        else if (value === 10)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-secondary px-3 py-0" style={{ borderRadius: "15px" }}>Boarding Period</span>
            )
        else if (value === 20)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-primary px-3 py-0" style={{ borderRadius: "15px" }}>Growing Period</span>
            )
        else if (value === 30)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-warning px-3 py-0" style={{ borderRadius: "15px" }}>Matured</span>
            )
        else if (value === 40)
            return (
                <span className="fs-6 fw-bold text-center text-white bg-gradient bg-danger px-3 py-0" style={{ borderRadius: "15px" }}>Ready To Sell</span>
            )
    }

    return (
        <div className="position-relative h-100"
        // style={{ height: "95%" }}
        >
            <div className="position-absolute overflow-auto my-1 w-100 h-100">

                {/* ORDER HISTORY */}
                <div className={`row justify-content-center mx-auto d-table w-100 h-100`}>

                    <div className={`d-flex justify-content-between bg-white py-2 mt-2 border-bottom`}>
                        <h className='display-6 bg-white fw-bolder'>LIVE STOCK</h>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "10vh", maxHeight: "4vh" }}>
                            <input className="border rounded-pill px-2 min-vw-25" type="text" value={SearchKey} placeholder="Search Keywords" onChange={(e) => setSearchKey(e.target.value)} />
                            <p className='fw-bold text-success my-auto px-1 mx-1' title="Search" type='button'>Search</p>
                            {/* <button className="btn fs-3 px-2 py-0 fad fa-plus text-success border-left" onClick={() => setToggle(true)} /> */}
                        </div>
                    </div>

                    {Array.isArray(Data) && Data.length ?
                        <table className={`table table-hover table-borderless table-responsive card-1 d-table mt-1`}>
                            <thead>
                                <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Farm ID</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Farm Name</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Batch ID/No</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Age</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Live Birds</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">AVG Cons.</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Unit Cost</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Total Cost</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Batch Status</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Representative</span></th>
                                    <th className="p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase"> Action </span></th>
                                </tr>
                            </thead>
                            {
                                Data.map((item, i) => (
                                    <tbody>
                                        <tr className="border-bottom text-center" key={i}>
                                            {/* <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{moment(item.Date).format('DD MMM YYYY')}</span></td> */}
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.BusinessID.id}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0">{item.BusinessID.Title}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.id + "/" + item.BatchNo}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{CalculateAge(item.IssueDate)} Days</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{item.Quantity.toLocaleString("en", { minimumFractionDigits: 0 })} PCS</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{parseFloat(item.UnitCons).toLocaleString("en", { minimumFractionDigits: 3 })} GM</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">BDT {parseFloat(item.UnitPrice).toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">BDT {parseFloat(item.Cost).toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>
                                            <td className="border-right p-1">{!item.Sell ?
                                                item.CondID.Season === "Winter" ? CalculateAge(item.IssueDate) <= 10 ? BirdStatus(1) : CalculateAge(item.IssueDate) <= 25 ? BirdStatus(2) : CalculateAge(item.IssueDate) >= 25 ? BirdStatus(3) : CalculateAge(item.IssueDate) >= 28 ? BirdStatus(4) : null :
                                                    item.CondID.Season === "Summer" ? CalculateAge(item.IssueDate) <= 5 ? BirdStatus(10) : CalculateAge(item.IssueDate) <= 25 ? BirdStatus(20) : CalculateAge(item.IssueDate) >= 25 ? BirdStatus(30) : CalculateAge(item.IssueDate) >= 28 ? BirdStatus(40) : null : null
                                                : BirdStatus(100)
                                            }</td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0"><i class="fad fa-user-tie pr-2"> </i>{item.BusinessID.RepID.id + ". " + item.BusinessID.RepID.FirstName + " " + item.BusinessID.RepID.LastName}</span> </td>
                                            <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">
                                                <button title="Remove Product" className="btn fs-5 px-2 py-0 fad fa-tags text-dark" onClick={() => { setStockItem(item); setSellModalShow(true) }} />
                                                {/* <button title="Update Product" className="btn fs-5 px-2 py-0 fad fa-edit text-dark" id="print" onClick={(e) => { setStockItem(item); setUpdateModalShow(true) }} /> */}
                                                <button title="Product Profile" className="btn fs-5 px-2 py-0 fad fa-sync-alt text-dark" id="view" onClick={(e) => exportPDF(e, item)} />
                                                <button title="Product Profile" className="btn fs-5 px-2 py-0 fad fa-eye text-dark" id="view" onClick={(e) => exportPDF(e, item)} />
                                            </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                            }
                        </table>
                        :
                        <div className={`d-flex justify-content-center align-items-center bg-white`}>
                            <h className='fs-2 fw-bold text-center text-success'>No Product Found!</h>
                        </div>
                    }
                </div>
            </div >
            {
                StockItem ?
                    <SellModal
                        FullName={StockItem.id + "/" + StockItem.BatchNo + ". " + StockItem.BusinessID.Title}
                        show={SellModalShow}
                        Value={StockItem.Sell}
                        Click={(e) => BatchPark(e)}
                        onReload={() => LoadLiveStock()}
                        onHide={() => { setStockItem(false); setSellModalShow(false) }}
                    />
                    : null
            }
            <InfoMessage
                header="Remove stock product!"
                body_header="Can not remove product"
                body="Product exist in physical store. So, you can not remove product without null stock"
                show={InfoModalShow}
                onHide={() => setInfoModalShow(false)}
            />
        </div >
    );
}

const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(LiveStock);