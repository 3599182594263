import { DISPLAY_OVERLAY } from './types';
import axios from 'axios';
import { checkToken } from './auth';
import { useDispatch } from 'react-redux';

export const getLabel = (value, arr) => {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].value === value) {
            return arr[i].label;
        }
    }
    return -"N/A"; //to handle the case where the value doesn't exist
}

export const OperationList = [
    { label: "N/A", value: 0 },
    { label: "Percentage (%)", value: 1 },
    { label: "Total Body / Total Feed (BG)", value: 2 },
    { label: "Total Body / Total Feed (KG)", value: 3 },
    { label: "Total Cost / Total Body (KG)", value: 4 },
    { label: "Sell Rate > Cost (Per KG) + Profit Margin", value: 5 },
    { label: "Quantity × Rate", value: 6 },
    { label: "Weight × Rate", value: 7 },
    { label: "Mortality > Deceased", value: 8 },
    { label: "Mortality < Deceased", value: 9 },
    { label: "Weight × Sell Rate", value: 10 },
    { label: "Quantity × Chick Rate", value: 11 },

    { label: "Batch Payment - Savings", value: 12 },
    { label: "Net Payment - Savings", value: 13 },
    { label: "Grand Payment - Savings", value: 14 },
    { label: "Stock Feed", value: 15 },
    { label: "Stock Medicine", value: 16 },
    { label: "Stock Bird", value: 17 },
    { label: "Payment", value: 18 },
    { label: "Net Payment", value: 19 },
    { label: "Grand Payment", value: 20 },
]

export const CurrencyList = [
    { label: "N/A", value: 0 },
    { label: "BDT", value: 1 },
    { label: "Percentage (%)", value: 2 },
    { label: "Array[]", value: 3 },
    { label: "Boolean", value: 4 }
]

export const ConditionScheme = [
    { label: "Feed Conversion Ratio (FCR) [BCF]", value: 1 },
    { label: "Credit Farm [BCF]", value: 2 },
    { label: "Profit & Loss Sharing (P/L Sharing) [BCF]", value: 3 },
    { label: "Market Value [BCF]", value: 4 },
    { label: "Fixed Rate [BCF]", value: 5 }
]

export const LoadBusiness = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_user_business/${id}`, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const BusinessPro = async (ID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_my_business/${ID}`, config);

        return res.data[0]
    } catch (err) {
        return true;
    }
}


export const SaveContract = async (CompanyID, BranchID, UserID, TypeID, Type, Title, CondID, SecurityMoney, Balance, RepID, FarmReg, BlankCheque, Agreement, ShedSize, Floor, Roof, WaterPot, FeedPot, Employee, Contact) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("UserID", UserID);
    formData.append("Title", Title);
    formData.append("CondID", CondID);
    formData.append("TypeID", TypeID);
    formData.append("Type", Type);
    formData.append("SecurityMoney", SecurityMoney);
    formData.append("Balance", Balance);
    formData.append("RepID", RepID);
    formData.append("FarmReg", FarmReg);
    formData.append("BlankCheque", BlankCheque);
    formData.append("Agreement", Agreement);

    formData.append("ShedSize", ShedSize);
    formData.append("Floor", Floor);
    formData.append("Roof", Roof);
    formData.append("WaterPot", WaterPot);
    formData.append("FeedPot", FeedPot);
    formData.append("Employee", Employee);
    formData.append("ContactNo", Contact);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/business/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveSubDealer = async (CompanyID, BranchID, UserID, TypeID, Type, Title, SecurityMoney, Target, Currency, Balance, RepID, FarmReg, BlankCheque, Agreement) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("UserID", UserID);
    formData.append("Title", Title);
    formData.append("TypeID", TypeID);
    formData.append("Type", Type);
    formData.append("SecurityMoney", SecurityMoney);
    formData.append("Balance", Balance);
    formData.append("RepID", RepID);
    formData.append("FarmReg", FarmReg);
    formData.append("BlankCheque", BlankCheque);
    formData.append("Agreement", Agreement);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/business/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveCustomer = async (CompanyID, BranchID, UserID, TypeID, Type, Title, RepID, FarmReg, BlankCheque, Agreement) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("UserID", UserID);
    formData.append("Title", Title);
    formData.append("TypeID", TypeID);
    formData.append("Type", Type);
    formData.append("RepID", RepID);
    formData.append("FarmReg", FarmReg);
    formData.append("BlankCheque", BlankCheque);
    formData.append("Agreement", Agreement);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/business/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}


export const SaveBatch = async (CompanyID, BranchID, UserID, BusinessID, BatchNo, CondID, IssueDate, Size, FCR, ABW, Cost, SellRate, NetPay, GrandPay, SavingRate, Saving, Status) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("UserID", UserID);
    formData.append("BusinessID", BusinessID);

    formData.append("BatchNo", BatchNo);
    formData.append("CondID", CondID);
    formData.append("IssueDate", IssueDate);
    formData.append("Size", Size);
    formData.append("FCR", FCR);
    formData.append("ABW", ABW);
    formData.append("Cost", Cost);
    formData.append("SellRate", SellRate);
    formData.append("NetPay", NetPay);
    formData.append("GrandPay", GrandPay);
    formData.append("SavingRate", SavingRate);
    formData.append("Saving", Saving);
    formData.append("Status", Status);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/batch/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const UpdateBatch = async (CompanyID, BranchID, UserID, BusinessID, BatchID, BatchNo, CondID, IssueDate, Size, FCR, ABW, Cost, SellRate, NetPay, GrandPay, SavingRate, Saving, Status) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("UserID", UserID);
    formData.append("BusinessID", BusinessID);
    formData.append("BatchNo", BatchNo);

    if (CondID !== null)
        formData.append("CondID", CondID)
    if (IssueDate !== null)
        formData.append("IssueDate", IssueDate)
    if (Size !== null)
        formData.append("Size", Size)
    if (FCR !== null)
        formData.append("FCR", FCR)
    if (ABW !== null)
        formData.append("ABW", ABW)
    if (Cost !== null)
        formData.append("Cost", Cost)
    if (SellRate !== null)
        formData.append("SellRate", SellRate)
    if (NetPay !== null)
        formData.append("NetPay", NetPay)
    if (GrandPay !== null)
        formData.append("GrandPay", GrandPay)
    if (SavingRate !== null)
        formData.append("SavingRate", SavingRate)
    if (Saving !== null)
        formData.append("Saving", Saving)
    formData.append("Status", Status);


    try {
        for (var pair of formData.entries()) {

        }

        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/batch/${BatchID}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }

}

export const UpdateContract = async (BusinessID, FarmID, Title, CondID, SecurityMoney, Balance, RepID, FarmReg, BlankCheque, Agreement, ShedSize, Floor, Roof, WaterPot, FeedPot, Employee, Contact) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BusinessID", BusinessID);
    formData.append("FarmID", FarmID);
    formData.append("Title", Title);
    formData.append("CondID", parseInt(CondID));
    formData.append("SCMoney", SecurityMoney);
    formData.append("Balance", Balance);
    formData.append("RepID", parseInt(RepID));
    formData.append("FarmReg", FarmReg ? 1 : 0);
    formData.append("BlankCheque", BlankCheque ? 1 : 0);
    formData.append("Agreement", Agreement ? 1 : 0);

    formData.append("ShedSize", ShedSize);
    formData.append("Floor", Floor);
    formData.append("Roof", Roof);
    formData.append("WaterPot", WaterPot);
    formData.append("FeedPot", FeedPot);
    formData.append("Employee", Employee);
    formData.append("ContactNo", Contact);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/business/${BusinessID}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const BISTerminate = async (id) => {


    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/business/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const RemoveBatch = async (id) => {


    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/batch/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}


export const BatchPro = async (ID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_batch/${ID}`, config);

        return res.data
    } catch (err) {
        return true;
    }
}

// export const CondList = async () => {
//     await checkToken();
//     const config = {
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${localStorage.getItem('access')}`,
//             'Accept': 'application/json'
//         }
//     };

//     try {
//         const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/cond_title/`, config);
//         return res.data
//     } catch (err) {
//         return true;
//     }
// }

export const SaveItem = async (SLNo, From, To, Rate, FirstCondID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("SLNo", SLNo);
    formData.append("From", From);
    formData.append("To", To);
    formData.append("Rate", Rate);
    formData.append("FirstCondID", FirstCondID);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/cond_rate/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadCondition = async () => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/condition/`, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const RemoveCondRate = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/cond_rate/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const RemoveOC = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/cond_rate/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const DeleteCondition = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/condition/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const CondImplement = async (ConID, Status, Value) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Status", Status ? 0 : 1);
    formData.append("Value", Value);
    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/condition/${ConID}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadCondList = async () => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_condition/`, config);


        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadMyFarms = async () => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/farm_list/`, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const GetFarm = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_farm/${id}`, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const Invoice = async (BisID, BatchID, InvoiceNo, Date, VatRate, Vat, Discount, Shipment, TotalPrice, Count, SellData, ProductSent_ID, ProductSent_Code) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BusinessID", BisID);
    formData.append("BatchID", BatchID);
    formData.append("InvoiceNo", InvoiceNo);
    formData.append("Date", Date);
    formData.append("VatRate", VatRate);
    formData.append("Vat", Vat);
    formData.append("Discount", Discount);
    formData.append("GrandTotal", TotalPrice);
    formData.append("ItemCount", Count);
    formData.append("Amount", Shipment);
    formData.append(`COA`, ProductSent_ID);
    formData.append(`COA_Code`, ProductSent_Code);

    Object.keys(SellData).map(e => {
        formData.append(`SellMapData[${e}]InvoiceNo`, InvoiceNo);
        formData.append(`SellMapData[${e}]SLNo`, SellData[e].SLNo);
        formData.append(`SellMapData[${e}]ItemCode`, SellData[e].ItemCode);
        formData.append(`SellMapData[${e}]UnitWeight`, SellData[e].UnitWeight);
        formData.append(`SellMapData[${e}]UnitPrice`, SellData[e].UnitPrice);
        formData.append(`SellMapData[${e}]Weight`, SellData[e].Weight);
        formData.append(`SellMapData[${e}]Qty`, SellData[e].Quantity);
        formData.append(`SellMapData[${e}]Rate`, SellData[e].Rate);
        formData.append(`SellMapData[${e}]SubTotal`, SellData[e].SubTotal);
        formData.append(`SellMapData[${e}]Status`, 1);
        formData.append(`SellMapData[${e}]COA`, ProductSent_ID);
        formData.append(`SellMapData[${e}]COA_Code`, ProductSent_Code);
    });


    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/sale/`, formData, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const LoadLadger = async (BusinessID, BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contract_ladger/${BusinessID}/${BatchID}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const FetchContractFWRLadger = async (BusinessID, BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/fwr_contract_ladger/${BusinessID}/${BatchID}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const SaveDR = async (Date, Age, BusinessID, BatchID, Mort, Cons, ABW, Stock, EntryType, Remark) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Date", Date);
    formData.append("Age", Age);
    formData.append("BusinessID", BusinessID);
    formData.append("BatchID", BatchID);
    formData.append("Mortality", Mort);
    formData.append("Cons", Cons);
    formData.append("ABW", ABW);
    formData.append("Stock", Stock);
    formData.append("EntryType", EntryType);
    formData.append("Remark", Remark);
    for (var pair of formData.entries()) {

    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/daily_record/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadDailyRecord = async (BusinessID, BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_dr/${BusinessID}/${BatchID}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const DeleteDR = async (id) => {


    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/daily_record/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const SaveBirdSell = async (BusinessID, BatchID, Date, InvoiceNo, Rate, Party, PartyAgent, CarNo, Driver) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BusinessID", BusinessID);
    formData.append("BatchID", BatchID);
    formData.append("Date", Date);
    formData.append("InvoiceNo", InvoiceNo);
    formData.append("Rate", parseInt(Rate));
    if (Party)
        formData.append("PartyID", Party.value);
    formData.append("PartyAgent", PartyAgent);
    formData.append("CarNo", CarNo);
    formData.append("Driver", Driver);

    for (var pair of formData.entries()) {

    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/init_bird_sell/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}


export const FetchInitData = async (BusinessID, BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_initialize/${BusinessID}/${BatchID}`, config);

        return res
    } catch (err) {
        return true;
    }
}


export const SaveScale = async (BatchID, InvoiceNo, Pices, Weight) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BatchID", BatchID);
    formData.append("InvoiceNo", InvoiceNo);
    formData.append("Qty", Pices);
    formData.append("Weight", Weight);
    for (var pair of formData.entries()) {

    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/scale/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}


export const UpdateScale = async (id, Pices, Weight) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("id", id);
    formData.append("Qty", Pices);
    formData.append("Weight", Weight);
    for (var pair of formData.entries()) {

    }
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/scale/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const SellRunningTotal = async (ID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/sell_running_total/${ID}`, config);

        return res.data[0]
    } catch (err) {
        return true;
    }
}

export const LoadSellReport = async (BatchID, InvoiceNo) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bs_sell_report/${BatchID}/${InvoiceNo}`, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadSellData = async (BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bs_sell_data/${BatchID}`, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadWeeklyData = async (BusinessID, BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_weekly_reports/${BusinessID}/${BatchID}`, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveCondition = async (Scheme, Title, Value, Season) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Scheme", Scheme);
    formData.append("Title", Title);
    formData.append("Value", Value);
    formData.append("Season", Season);

    for (var pair of formData.entries()) {

    }
    try {

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/condition/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const UpdateCondition = async (id, Scheme, Title, Value, Season) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Scheme", Scheme);
    formData.append("Title", Title);
    formData.append("Value", Value);
    formData.append("Season", Season);
    for (var pair of formData.entries()) {

    }
    try {

        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/condition/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveOtherCond = async (CondID, SLNo, Title, Rate, Currency, Operation, Times, Status, COA_ID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CondID", CondID);
    formData.append("SLNo", SLNo);
    formData.append("Title", Title);
    formData.append("Rate", Rate);
    formData.append("Currency", Currency);
    formData.append("Operation", Operation);
    formData.append("Times", Times);
    formData.append("Status", Status);
    formData.append("COA_ID", COA_ID);
    for (var pair of formData.entries()) {

    }
    try {

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/other_cond/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveSecondCond = async (CondID, SLNo, Title, Type, Rate, Currency, COA_ID, Status) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CondID", CondID);
    formData.append("SLNo", SLNo);
    formData.append("Title", Title);
    formData.append("Type", Type);
    formData.append("Rate", Rate);
    formData.append("Currency", Currency);
    formData.append("COA_ID", COA_ID);
    formData.append("Status", Status);

    for (var pair of formData.entries()) {

    }
    try {

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/second_cond/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchAccounts = async () => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_coa/`, config);
        return res.data
    } catch (err) {
    }
}

export const UpdateOtherCond = async (id, SLNo, Title, Rate, Currency, Operation, Times, Status, COA_ID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("SLNo", SLNo);
    formData.append("Title", Title);
    formData.append("Rate", Rate);
    formData.append("Currency", Currency);
    formData.append("Operation", Operation);
    formData.append("Times", Times);
    formData.append("Status", Status);
    formData.append("COA_ID", COA_ID);

    for (var pair of formData.entries()) {

    }
    try {

        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/other_cond/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchDispatch = async (COA, Date) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contract_dispatch/${COA}/${Date}`, config);
        return res
    } catch (err) {
    }
}

export const FetchLiveStock = async () => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/live_stock/`, config);

        return res
    } catch (err) {
    }
}

export const ParkBatch = async (id, value) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("Sell", value);
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/batch_park/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadBatchAccount = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/batch_payment_rcpt/${id}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const LoadBatchAssesment = async (id, Status) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_batch_assesment/${id}/${Status}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const LoadProfitNLoss = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/pl_calculate/${id}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const LoadPayment = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/batch_payment/${id}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const FetchInvoice = async (InvoiceNo) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_invoice/${InvoiceNo}`, config);

        return res
    } catch (err) {
    }
}

export const RecallProduct = async (id, ItemCode, Qty, Weight, SubTotal) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("ItemCode", ItemCode);
    formData.append("Qty", Qty);
    formData.append("Weight", Weight);
    formData.append("SubTotal", SubTotal);
    for (var pair of formData.entries()) {

    }
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/con_dis_inv_single_item_remove/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const DispatchInvoiceDelete = async (id) => {


    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/remove_dis_inv/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}


export const FetchPartyInvoiceNo = async (InvoiceType) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_party_invoice_no/${InvoiceType}`, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const LeftBird = async (inv, Qty, Wt, VatRate, Vat, Discount, GrandTotal, PaidAmount, Due) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("StockQty", Qty);
    formData.append("StockWeight", Wt);
    formData.append("VatRate", VatRate);
    formData.append("Vat", Vat);
    formData.append("Discount", Discount);
    formData.append("GrandTotal", GrandTotal);
    formData.append("PaidAmount", PaidAmount);
    formData.append("Due", Due);
    for (var pair of formData.entries()) {

    }
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/init_bird_sell/${inv}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const RemoveInitialization = async (inv) => {


    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/init_bird_sell/${inv}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const BirdSellReturn = async (id, Qty, Weight) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Qty", Qty);
    formData.append("Weight", Weight);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/my_stock/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const DeleteScale = async (id) => {

    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/scale/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const SaveBatchStock = async (array, BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("BatchID", BatchID);
    formData.append("Data", JSON.stringify(array));

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/batch_stock/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveBatchAcc = async (array, BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BatchID", BatchID);
    formData.append("Data", JSON.stringify(array));

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/batch_acc/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}


export const LoadBatchSummery = async (BatchID) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_batch_summery/${BatchID}`, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const LoadBirdSellHistory = async (Date) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_bird_sell_history/${Date}`, config);

        return res.data
    } catch (err) {
        return true;
    }
}
export const FetchAllFarmPayment = async () => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/farmer_pending_payment/`, config);

        return res
    } catch (err) {
    }
}


export const FetchBatch = async (id) => {
    await checkToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/payment_batch/${id}`, config);

        return res.data[0]
    } catch (err) {
        return true;
    }
}
