import * as moment from 'moment'
import { MyOrders, colourStyles, SendOrder, MyProductList } from '../../../actions/SuppliersAPI';
import { LoadLadger, LoadSellReport } from '../../../actions/ContractAPI';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../../actions/auth';
import { connect, useDispatch } from 'react-redux';

import { CreateMessage, InfoMessage } from "../../Modals/ModalForm.js";
import { DISPLAY_OVERLAY } from '../../../actions/types';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';
import { exportPDF } from '../../Suppliers/Class/OrderPDF';

const SellReport = ({ UserID, BisID, BatchID, SupplierID, user, list, setList }) => {

    // const CompanyID = user.CompanyID;
    // const BranchID = user.BranchID;
    // const BranchName = user.BranchName;

    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const [InfoModalShow, setInfoModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [BSData, setBSData] = useState(false)
    const [Data, setData] = useState(false)
    const [MyProList, setMyProList] = useState(false)
    const [OrderData, setOrderData] = useState([])
    const [Count, setCount] = useState(null)
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();

    const [formData, setFormData] = useState({
        ItemCode: "",
        Title: "",
        UnitWeight: "",
        UnitPrice: "",
        Quantity: "",
    });
    const { ItemCode, Title, UnitWeight, UnitPrice, Quantity } = formData;

    useEffect(() => {
        // My_Ladger();
        SellReportData();
    }, [])

    const My_Ladger = async () => {
        if (user !== null) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var result = await LoadLadger(BisID, BatchID);
            if (result !== true)
                setData(result.data);

            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            history.push(`/farm_mng/${UserID}/${BisID}/${BatchID}`);
        }
    }

    const SellReportData = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var result = await LoadSellReport(BatchID, 0);

        if (result !== true) {
            setBSData(result);
        } else {
            // history.push('/not_found');
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const LoadProductItems = async () => {
        if (user !== null) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var ProductItems = await MyProductList(user.CompanyID, user.BranchID, SupplierID);
            if (ProductItems !== true)
                setMyProList(ProductItems.data);
            // 
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            history.push('/my_supplier');
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSubmit();
        }
    }

    const getTotal = () => {
        let TotalPrice = 0;
        const price = OrderData.map(row => row.Quantity * row.UnitPrice);
        if (price.length > 0) {
            TotalPrice = price.reduce((acc, val) => acc + val);
        }
        return TotalPrice;
    }

    const QuantityTotal = OrderData.reduce((TotalQuantity, myvalue) => TotalQuantity + parseInt(myvalue.Quantity, 10), 0);

    const deleteRow = (i) => {
        // make new rows. note: react state is immutable.
        const newRows = OrderData.splice(i, 1).concat(OrderData.slice(i + 1));
        // setRows({ OrderData: newRows })
        setCount(Count - 1)
    };

    const onSubmit = (e) => {
        if (formData.Quantity === "" || formData.Quantity === undefined || formData.ItemCode === "" || formData.ItemCode === undefined || formData.UnitPrice === "" || formData.UnitPrice === undefined) {
            setInfoModalShow(true)
        } else {
            setOrderData([...OrderData, formData]);
            setCount(Count + 1);
            setFormData({
                Title: "",
                UnitWeight: "",
                UnitPrice: "",
                Quantity: "",
                ItemCode: "",
            });
        }
    }

    const QtyTotal = (item) => item.reduce((Quantity, myvalue) => Quantity + parseInt(myvalue.Qty, 10), 0);
    const WeightTotal = (item) => item.reduce((Weight, myvalue) => Weight + parseFloat(myvalue.Weight, 10), 0);

    return (
        <div className="position-relative mb-5 mt-2" style={{ height: "95%" }}>

            <div className="position-absolute overflow-auto w-100 h-75 bg-white">
                <p className="fs-4 fw-bolder text-center py-2 m-0">SELL REPORT</p>

                <div className="row justify-content-center mx-2">
                    {
                        Array.isArray(BSData) && BSData.length ? BSData.map((item, i, Data) => (
                            <div className="col-md-3 justify-content-center align-items-center">
                                <table className={`table table-hover border`}>
                                    <thead>
                                        <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                            <th className="border-top-0 border-right px-2 py-1 align-middle"><span className="fs-6 fw-bolder text-dark">SLNo</span></th>
                                            <th className="border-top-0 border-right px-2 py-1 align-middle"><span className="fs-6 fw-bolder text-dark">Qty</span></th>
                                            <th className="border-top-0 border-right px-2 py-1 align-middle"><span className="fs-6 fw-bolder text-dark">Weight</span></th>
                                        </tr>
                                    </thead>
                                    {
                                        Array.isArray(item) && item.length ? item.map((value, n, Data) => (
                                            <tbody>
                                                <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-center text-dark px-2 py-1">{n + 1}</span></td>
                                                <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-center text-dark px-2 py-1" >{value.Qty}</span></td>
                                                <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-right text-dark px-2 py-1">{value.Weight}</span> </td>
                                            </tbody>
                                        ))
                                            : null
                                    }
                                    <tbody>
                                        <td colSpan="3" className="py-0 px-2"><span className="d-block fs-6 fw-bolder text-center text-dark px-2 py-1">{QtyTotal(item) + " PCS,  " + WeightTotal(item).toLocaleString("en", { minimumFractionDigits: 3 }) + " KG"}</span></td>
                                    </tbody>

                                </table>
                            </div>
                        ))
                            : null
                    }
                </div>
                {/* <div className="d-flex justify-content-center bg-white py-2"> */}


                {/* <div className="col-md-3 justify-content-center align-items-center">
                        <table className={`table table-hover table-borderles table-responsive card-1 d-table justify-content-center align-items-center position-absolute overflow-auto top-0 start-50 translate-middle-x p-2 m-0`} style={{ maxHeight: "70%" }}>

                            <thead>
                                <tr className="text-center">
                                    <th className="p-1 border-0" colspan="11">
                                        <p className="fs-4 fw-bolder text-center py-2 m-0">SELL REPORT</p>
                                    </th>
                                </tr>
                                <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                    <th className="border-right px-2 py-1 align-middle"><span className="fs-6 fw-bolder text-dark">SLNo</span></th>
                                    <th className="border-right px-2 py-1 align-middle"><span className="fs-6 fw-bolder text-dark">Qty</span></th>
                                    <th className="px-2 py-1 align-middle"><span className="fs-6 fw-bolder text-dark">Weight</span></th>
                                </tr>
                            </thead>
                            {
                                Array.isArray(BSData) && BSData.length ? BSData.map((item, i, Data) => (
                                    <tbody>
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-center text-dark px-2 py-1">{i + 1}</span></td>
                                        <td className="border-right p-0"><span className="d-block fs-6 fw-bold text-center text-dark btn px-2 py-1" >{item.Qty}</span></td>
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-right text-dark px-2 py-1">{item.Weight}</span> </td>
                                    </tbody>
                                ))
                                    : null
                            }
                        </table>
                    </div> */}



                {/* </div> */}
            </div >

        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
    // BisID: props.BisID,
    // BatchID: props.BatchID,
    // UserID: props.UserID
});

export default connect(mapStateToProps, { logout })(SellReport);