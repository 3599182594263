import * as moment from 'moment'
import { LoadBatchAssesment, LoadBatchSummery } from '../../../actions/ContractAPI';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../../actions/auth';
import { connect, useDispatch } from 'react-redux';

import { CreateMessage, InfoMessage } from "../../Modals/ModalForm.js";
import { DISPLAY_OVERLAY } from '../../../actions/types';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';
import { exportPDF } from '../../Suppliers/Class/OrderPDF';

const BatchSummery = ({ CompanyID, BranchID, BisID, BatchID, SupplierID, user, list, setList }) => {
    const [SummeryData, setSummeryData] = useState(false);

    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();


    useEffect(() => {
        FetchBatchAssesment();
    }, [])


    const FetchBatchAssesment = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var result = await LoadBatchAssesment(BisID, 4);

        if (result !== true) {
            setSummeryData(result.data.Summery);
        }

        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    return (
        <div className="position-relative mb-5" style={{ height: "85%" }}>

            <div className="position-absolute overflow-auto my-1 w-100 h-75 bg-white">

                <div className="d-flex justify-content-center bg-white py-2">
                    <div className="col-md-11 justify-content-center align-items-center">
                        <p className="fs-4 fw-bolder text-center py-2 m-0">SUMMERY</p>

                        <div className="row justify-content-center align-items-center">
                            <table className={`table table-borderless table-responsive card-1 d-table`}>
                                <tbody>
                                    <tr className="border-bottom border-top text-center text-success">
                                        <td className="p-1" colspan="2"><span className="fs-5 fw-bolder text-center py-2">BATCH SUMMERY</span></td>
                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>
                                        <td className="p-1" colspan="3"><span className="fs-5 fw-bolder text-center py-2">TECHNICAL TERMS</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Batch Size</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.BatchSize.toLocaleString("en", { minimumFractionDigits: 0 }) + " PCS" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Total Feed Cons.</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.FeedCons.toLocaleString("en", { minimumFractionDigits: 0 }) + " BG/" + (parseFloat(SummeryData.FeedCons) * parseFloat(SummeryData.CondValue)).toLocaleString("en", { minimumFractionDigits: 3 }) + " KG" : "N/A"}</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Issue Date</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? moment(SummeryData.IssueDate).format('DD MMM YYYY') + "/" + SummeryData.Day + " Day" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">AVG FEED CONS.</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.AVGFeedCons.toLocaleString("en", { minimumFractionDigits: 3 }) + " KG" : "N/A"}</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Sells Date</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? moment(SummeryData.SellDate).format('DD MMM YYYY') : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">TOTAL BODY WEIGHT</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.BodyWeight.toLocaleString("en", { minimumFractionDigits: 3 }) + " KG" : "N/A"}</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Total Solded Bird</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.SoldBird.toLocaleString("en", { minimumFractionDigits: 0 }) + " PCS" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">AVG BODY WEIGHT</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.AVGSellWeight.toLocaleString("en", { minimumFractionDigits: 3 }) + " KG" : "N/A"}</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Stock Bird</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.StockBird.toLocaleString("en", { minimumFractionDigits: 0 }) + " PCS" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">FCR</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.FCR.toFixed(2) : "N/A"}</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Expired Chicks</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.Expire.toLocaleString("en", { minimumFractionDigits: 0 }) + " PCS" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Actual FCR</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.FCR.toFixed(2) : "N/A"}</span></td>
                                    </tr>

                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Lost Bird/Chick</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.Lost.toLocaleString("en", { minimumFractionDigits: 0 }) + " PCS" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Mortality</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.Mortality.toFixed(2) + "%" : "N/A"}</span></td>
                                    </tr>



                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Feed Dispatch</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.FeedSend.toLocaleString("en", { minimumFractionDigits: 0 }) + " BG/" + (parseFloat(SummeryData.FeedSend) * parseFloat(SummeryData.CondValue)).toLocaleString("en", { minimumFractionDigits: 3 }) + " KG" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Livability</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.Livability.toFixed(2) + "%" : "N/A"}</span></td>
                                    </tr>

                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Feed Recall</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.FeedRecall.toLocaleString("en", { minimumFractionDigits: 0 }) + " BG/" + (parseFloat(SummeryData.FeedRecall) * parseFloat(SummeryData.CondValue)).toLocaleString("en", { minimumFractionDigits: 3 }) + " KG" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Performance Efficiency Factor (PEF)</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.PEF.toFixed(2) : "N/A"}</span></td>
                                    </tr>

                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Feed Stock</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.FeedStock.toLocaleString("en", { minimumFractionDigits: 0 }) + " BG/" + (parseFloat(SummeryData.FeedStock) * parseFloat(SummeryData.CondValue)).toLocaleString("en", { minimumFractionDigits: 3 }) + " KG" : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Farm Economy Index (FEI)</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.FEI.toFixed(2) : "N/A"}</span></td>
                                    </tr>


                                </tbody>

                            </table>


                            <table className={`table table-borderless table-responsive card-1 d-table`}>
                                <tbody>
                                    <tr className="border-bottom text-center text-success">
                                        <td className="p-1" colspan="5"><span className="fs-5 fw-bolder text-center py-2">CONTRACT SUMMERY</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Contract Condition</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">{SummeryData ? SummeryData.Condition : "N/A"}</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Addmission Charge</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">BDT 1.00 (Per Chick)</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Equivalent Rate</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">BDT 12.33</span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Savings</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">BDT 2,400.00</span></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2"></span></td>

                                        <td className="border-right border-left p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2"></span></td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Security Money</span></td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark px-2">BDT 5,000.00</span></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div >

        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(BatchSummery);