import React, { useState, useEffect, Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch, Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { logout } from '../../../../actions/auth';
import { GetSuppliers } from '../../../../actions/SuppliersAPI';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import { IoCloudUploadOutline, IoImagesOutline, IoRemoveCircleOutline } from "react-icons/io5";
// import Products from './Products';
import ProductList from './ProductItems';
import Overview from './Overview';
import Orders from './Orders';
import Ladger from './Ladger/Ladger';

const SupplierMain = ({ display, SupplierID, list, setList }) => {
    let { path, url } = useRouteMatch();

    const [Data, setData] = useState(null)
    const [Widget, setWidget] = useState(false)
    const [View, setView] = useState(1)
    const dispatch = useDispatch();
    const [Activity, setActivity] = useState(true);
    const [Profile, setProfile] = useState(false);
    const [ProductPro, setProductPro] = useState(false);

    useEffect(() => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        LoadSuppliers();
    }, [])

    const LoadSuppliers = async () => {
        var result = await GetSuppliers(SupplierID);

        if (result !== true) {

            setData(result);
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
            history.push('/my_supplier');
        }
    }

    const history = useHistory();
    // const FetchUser = async (id) => {
    //     var User_Data = await LoadProfile(id);
    //     history.push('/pending_user', { UserData: User_Data.data });
    // }

    return (
        <>
            <div className={"item grid-group-item list-group-item m-0"}>
                <div className="box thumbnail card py-2 shadow-none m-0 h-100">

                    <div className="img-event d-flex flex-column justify-content-center align-items-end" style={{ minHeight: "20vh", width: "40%" }}>
                        <div className="row mx-auto d-table">
                            <div className="img_container">
                                <img src={Data ? Data.Logo : process.env.REACT_APP_API_URL + "/Media/ProductImageNotAvailable.png"} className="img-fluid rounded mb-0 mx-auto d-table" alt="avatar" style={{ height: "15vh" }} />
                                <div className="middle">
                                    <input
                                        type="file"
                                        id="pic"
                                        className="d-none"
                                        accept="image/*"
                                    // onChange={(e) => ImageChange(e)} 
                                    />
                                    <label
                                        className='btn btn-outline-success shadow-lg'
                                        for="pic"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Choose Image">
                                        <IoImagesOutline size={25} />
                                    </label>
                                    {Data ?
                                        <Fragment>
                                            <label
                                                className='btn btn-outline-success shadow-lg'
                                                for="file1"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Upload Image"
                                            // onClick={(e) => UploadImage(e)}
                                            >
                                                <IoCloudUploadOutline size={25} />
                                            </label>
                                            <button
                                                className='btn btn-outline-success shadow-lg'
                                                for="file1"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Remove selected image"
                                                type="button"
                                            // onClick={() => ImageRemove()}
                                            >
                                                <IoRemoveCircleOutline size={25} />

                                            </button>
                                        </Fragment>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="caption card-body d-flex flex-column justify-content-center align-items-start py-0 px-2 w-50" style={{ minHeight: "20vh", width: "60%" }}>
                        <p className="group inner list-group-item-text fs-5 m-0">
                            <h className="display-6 d-flex justify-content-start">
                                {Data ? Data.id + " | " + Data.CmpName : null}
                            </h>
                            <p className="fs-5 fw-bold m-0">
                                Address:
                            </p>
                            {Data ? Data.CPFAddress : null}
                        </p>
                    </div>

                </div>
            </div>

            <div className="header d-flex justify-content-center bg-white my-1">
                <div className="d-flex justify-content-start overflow-auto">
                    <Link className='fw-bold fs-5 text-success p-2' title="Products" type='button' to={`${url}/ladger`}>Transactions</Link>

                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }}>
                        {/* <div className="cs_inner"></div> */}
                    </div>

                    <Link className="fw-bold fs-5 text-success p-2" type='button' to={`${url}/orders`}>D/O</Link>
                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }} />
                    <Link className='fw-bold fs-5 text-success p-2' title="Products" type='button' to={`${url}/product_items`}>Products</Link>
                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }} />
                    <Link className='fw-bold fs-5 text-success p-2' title="Agents" type='button' to={url}>Agents</Link>
                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }} />
                    <Link className="fw-bold fs-5 text-success p-2" type='button' to={url}>Banks</Link>

                </div>
            </div>

            <Switch>
                <Route exact path={path}> <ProductList SupplierID={SupplierID} list={list} setList={setList} /> </Route>
                <Route exact path={`${path}/product_items`}> <ProductList SupplierID={SupplierID} list={list} setList={setList} /> </Route>
                <Route exact path={`${path}/ladger`}> <Ladger SupplierID={SupplierID} BisData={Data} list={list} setList={setList} /> </Route>
                <Route exact path={`${path}/orders`}> <Orders SupplierID={SupplierID} list={list} setList={setList} /> </Route>
                <Route render={(props) => <Redirect to="/not_found" />} />
            </Switch>
        </>
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    SupplierID: props.match.params.sup_id
});

export default connect(mapStateToProps, { logout })(SupplierMain);