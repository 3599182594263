import * as moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../actions/auth';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../actions/types';
// import errorIcon from '../../../assets/error.png';
// import infoIcon from '../../../assets/info.png';
// import successIcon from '../../../assets/success.png';
// import warningIcon from '../../../assets/warning.gif';
import { FetchCashFlow } from '../../actions/APIHandler';
import { customHeader, locales } from "../Suppliers/Class/datepicker";
import Datepicker from 'react-datepicker';
let today = new Date();

const CashLadger = ({ CompanyID, BranchID, SupplierID, user, UserID, BisID, list, setList, accounts }) => {
    const initialValue = { value: 0, label: "" };
    const [Data, setData] = useState(false);
    const [DateTo, setDateTo] = useState(today);
    const [DateFrom, setDateFrom] = useState(today);
    const [SearchKey, setSearchKey] = useState(false)
    const [locale, setLocale] = useState('en');
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        CashFlow();
        setDateTo(today);
    }, [])

    const CashFlow = async () => {

        let CashAC_ID = accounts ? accounts.CashAC_ID : null
        if (user !== null && CashAC_ID !== null) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var result = await FetchCashFlow(CashAC_ID);
            if (result !== true)
                setData(result.data);

            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            // history.push(`/farm_mng/${UserID}/${BisID}/${BatchID}`);
        }
    }

    const DateHandler = async (e) => {
        let date = moment(e).format("YYYY-MM-DD");

        if (DateFrom.getTime() > e.getTime()) {

        } else {
            // dispatch({ type: DISPLAY_OVERLAY, payload: true });
            // // var result = await LoadBirdSellHistory(date);
            // 
            // // if (result !== true) {
            // //     setData(result);
            // // } else {
            // //     // history.push('/not_found');
            // // }
            // dispatch({ type: DISPLAY_OVERLAY, payload: false });
            setDateTo(e)
        }
    }

    return (
        <div className="row justify-content-center py-2 px-0 h-100">
            <div className="col-md-12 justify-content-center align-items-center h-100">
                <div className={`d-flex justify-content-between bg-white py-2 px-2`}>
                    <p className='display-6 bg-white fw-bolder m-0'>CASH FLOW</p>
                    <p className='display-6 bg-white fw-bold m-0'>{moment(today).format("DD MMM YYYY")}</p>
                    <div className="d-flex justify-content-end">
                        <Datepicker
                            selected={DateFrom}
                            className="form-control fs-5 fw-bold round_radius50px text-center"
                            dateFormat="dd MMM yyyy"
                            onChange={(e) => setDateFrom(e)}
                            renderCustomHeader={props => customHeader({ ...props, locale })}
                            locale={locales[locale]}
                            placeholderText="Date"
                        />
                        <p className='fw-bold text-success my-auto px-1 mx-1' title="Search" type='button'>To</p>
                        <Datepicker
                            selected={DateTo}
                            className="form-control fs-5 fw-bold round_radius50px text-center"
                            dateFormat="dd MMM yyyy"
                            onChange={(e) => DateHandler(e)}
                            renderCustomHeader={props => customHeader({ ...props, locale })}
                            locale={locales[locale]}
                            placeholderText="Date"
                        />

                        <button className="btn fs-3 px-2 ml-2 py-0 text-dark border-left"
                        // onClick={() => setToggle(true)}
                        ><i class="fad fa-file-pdf"></i></button>
                        {/* <input className="border rounded-pill px-2 min-vw-25 mx-2" type="text" value={SearchKey} placeholder="Search Keywords" onChange={(e) => setSearchKey(e.target.value)} />
                                <p className='fw-bold text-success my-auto px-1 mx-1' title="Search" type='button'>Search</p>
                                <button className="btn fs-3 px-2 py-0 fad fa-plus text-success border-left" onClick={() => setToggle(true)} /> */}
                    </div>
                </div>

                <div className="position-absolute overflow-auto my-1 w-100 h-100 bg-white py-2 px-2">
                    <table className={`table table-hover table-borderless table-responsive card-1 d-table justify-content-center`} style={{ maxHeight: "70%" }}>
                        <thead>
                            <tr className="text-center border-top" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Date</span></th>
                                <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Type</span></th>
                                <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Title</span></th>
                                {/* <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Details</span></th> */}
                                <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">J.P. No</span></th>
                                <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Debit</span></th>
                                <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Credit</span></th>
                                <th className="border-right py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Balance</span></th>
                                <th className="py-1 align-middle"><span className="fs-6 fw-bolder text-dark text-uppercase">Details</span></th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(Data) && Data.length ? Data.map((item, i, Data) => (
                                    <tr className="border-bottom text-center" title={item.SellsMan} tooltip={item.SalesMan} key={i}>
                                        {
                                            parseInt(item.Order) === 3 ? <td className="border-right py-0 px-2"><span className="fs-6 fw-bold text-center text-dark" style={{ whiteSpace: 'nowrap' }}>{new window.Date(item.Date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' ')}</span>
                                            </td> :
                                                parseInt(item.SLNo) === 1 ?
                                                    <td rowSpan={parseInt(item.Count)} className="border-right py-0 px-2"><span className="fs-6 fw-bold text-center text-dark">{new window.Date(item.Date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' ')}</span>
                                                    </td>
                                                    : null}
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-left text-dark" style={{ whiteSpace: 'nowrap' }}>{item.Type}</span></td>
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-left text-dark" style={{ whiteSpace: 'nowrap' }}>{item.Title}</span></td>
                                        {/* <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-left text-dark">{item.Details}</span></td> */}
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-center text-dark btn px-2" >{item.RefNo}</span></td>
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-right text-dark">{parseFloat(item.Debit) === 0 ? "—" : (item.Debit).toLocaleString("en-BD", { minimumFractionDigits: 2 })}</span> </td>
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-right text-dark">{parseFloat(item.Credit) === 0 ? "—" : (item.Credit).toLocaleString("en-BD", { minimumFractionDigits: 2 })}</span> </td>
                                        <td className="border-right py-0 px-2"><span className="d-block fs-6 fw-bold text-right text-dark">{(item.Balance).toLocaleString("en-BD", { minimumFractionDigits: 2 })}</span> </td>
                                        <td className="border-0 py-0 px-2"><span className="d-block fs-6 fw-bold text-left text-dark">{item.Details}</span> </td>
                                    </tr>
                                ))
                                    : null
                            }
                        </tbody>

                    </table>
                </div>

            </div>

            <div className="col-md-12 justify-content-center align-items-center h-100">

            </div>
        </div>
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
    accounts: state.auth.accounts,
});

export default connect(mapStateToProps, { logout })(CashLadger);