import * as moment from 'moment'
import { SaveProductPro, ProductProList, UpdateImage, UpdateProductPro } from '../../../../actions/SuppliersAPI';
import { FaCodeBranch, FaUserTie } from "react-icons/fa";
import React, { Fragment, useEffect, useState } from 'react';
import { IoCloudUploadOutline, IoImagesOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../../../actions/auth';
import { connect, useDispatch } from 'react-redux';

import { CreateMessage } from "../../../Modals/ModalForm.js";
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../../assets/error.png';
import infoIcon from '../../../../assets/info.png';
import successIcon from '../../../../assets/success.png';
import warningIcon from '../../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';

const Overview = ({ SupplierID, list, setList, setProdcutPro, level, sub_level }) => {

    // 
    // const CompanyID = user.CompanyID;
    // const BranchID = user.BranchID;
    // const BranchName = user.BranchName;
    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [BankLists, setBankLists] = useState(initialValue)
    const [BBLists, setBBLists] = useState(initialValue)
    const [Data, setData] = useState(false)
    const [TempData, setTempData] = useState(false)
    const [Error, setError] = useState({});
    const [Step, setStep] = useState(null)
    const [RefLists, setRefLists] = useState(initialValue);
    const [RepLists, setRepLists] = useState(initialValue);
    const [Visibility, setVisibility] = useState("d-table");
    const [AccordLbl, setAccordLbl] = useState("Add New Product Profile");
    let toastProperties = null;
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        id: "",
        Title: "",
        Description: null,
        Specification: null,
        Type: null,
        Category: null,
        Status: null,
        Image: "",
        ImagePrv: ""
    });

    useEffect(() => {
        // LoadProductList()
    }, [])

    const LoadProductList = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var ProductList = await ProductProList(SupplierID);
        if (ProductList !== true)
            setData(ProductList.data);

        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const { id, Title, Description, Specification, Type, Category, Status, Image, ImagePrv } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onBlur = (e) => {

        if (e.target.value === "") { setFormData({ ...formData, [e.target.name]: TempData, }); }
    }

    const handleFocus = (e) => {
        setTempData(e.target.value)
        e.target.select()
    };

    const Create_Product_Pro = async e => {
        setCreateModalShow(false)
        setError({})
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: true
        });
        e.preventDefault();
        const result = await SaveProductPro(SupplierID, Title, Description, Specification, Type, Category, Status, Image, ImagePrv);

        if (result !== true) {
            if (result.error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Invalid Data',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Success',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                LoadProductList();
                AccordionToggle();
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
        } else {
            setList([...list, toastProperties = {
                id: 1,
                title: 'Error',
                description: "Failed to save product profile. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });

    };

    const Update_Product_Pro = async e => {
        setUpdateModalShow(false)
        setError({})
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: true
        });
        e.preventDefault();
        const result = await UpdateProductPro(SupplierID, id, Title, Description, Specification, Type, Category, Status, Image, ImagePrv);

        if (result !== true) {
            if (result.error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Invalid Data',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Success',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                LoadProductList();
                AccordionToggle();
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
        } else {
            setList([...list, toastProperties = {
                id: 1,
                title: 'Error',
                description: "Failed to update product profile. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });

    };


    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: 0, boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", height: "25px", borderRadius: '0px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                borderRadius: '20px',
            };
        },
        menu: base => ({
            ...base,
            borderRadius: '0px',
            outline: 0,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            padding: '5px'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '25px',
            padding: '0 3px',
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        })
    };

    const ImageChange = (e) => {
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: true
        });
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setFormData({ ...formData, "Image": file, "ImagePrv": reader.result })
        }

        reader.readAsDataURL(file)
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: false
        });
    }

    const ImageRemove = () => {
        setFormData({ ...formData, "ImagePrv": '' })
    }

    const UploadImage = async e => {


        e.preventDefault();
        const result = await UpdateImage(id, Image);

    };

    const AccordionToggle = () => {
        setFormData({
            id: "",
            Title: "",
            Description: "",
            Specification: "",
            Type: null,
            Category: null,
            Status: null,
            Image: "",
            ImagePrv: ""
        });
        setStep(Step === "ProductAddition" ? null : "ProductAddition");
        setVisibility(Visibility === "d-none" ? "d-table" : "d-none")
        setAccordLbl("Add New Product Profile")
    }

    const EditProduct = (item) => {
        setFormData(item)
        setFormData({
            id: item.id,
            Title: item.Title,
            Description: item.Description,
            Specification: item.Specification,
            Type: item.Type,
            Category: item.Category,
            Status: item.Status,
            Image: "",
            ImagePrv: item.Image
        });

        setStep(Step === "ProductAddition" ? null : "ProductAddition");
        setVisibility(Visibility === "d-none" ? "d-table" : "d-none")
        setAccordLbl("Update Product")
    }


    return (
        <div className="position-relative h-100">
            <div className="position-absolute overflow-auto my-1 pb-5 w-100 h-75">

                <div className="row justify-content-center mx-auto d-table w-100 h-100">
                    {
                        level === 8 || level === 9 ?
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto d-table px-0">
                                <div className="accordion accordion-flush px-0" id="accordionFlushExample">
                                    <div className="accordion-item border-0 bg-transparent">
                                        <h className="accordion-header =" id="flush-headingOne">
                                            <button className={`cs_accordion-button ${Step === "ProductAddition" ? "collapse show" : "collapsed"}  shadow-none py-2 my-1 fs-4 fw-bold text-success bg-white shadow-sm`} style={{ borderLeft: "5px solid #28A745" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={Step === "ProductAddition" ? "true" : "false"} aria-controls="flush-collapseOne"
                                                id="ProductAddition" onClick={() => AccordionToggle()}>
                                                {AccordLbl}
                                            </button>
                                        </h>
                                        <div id="ProductAddition" className={`accordion-collapse collapse ${Step === "ProductAddition" ? "show" : null}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample" style={{}}>
                                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto d-table h-100">
                                                <div className="row mb-5">
                                                    <div className="col-lg-3 justify-content-center mx-auto d-table"
                                                        style={{
                                                            backgroundColor: "#F4F7FC",
                                                            border: "1px solid #d3d3d3",
                                                            fontWeight: "bold",
                                                            maxWidth: "180px",
                                                        }}>
                                                        <p className="mx-auto d-table border-bottom w-100 mb-2 text-center">
                                                            Product Image
                                                        </p>

                                                        <div className="img_container">
                                                            <img
                                                                src={
                                                                    ImagePrv
                                                                        ? ImagePrv
                                                                        : process.env.REACT_APP_API_URL + "/Media/avatar.jpg"
                                                                }
                                                                className="image img-fluid rounded-square mx-auto d-table"
                                                                width="150"
                                                                alt="avatar"
                                                            />

                                                            <div className="middle">
                                                                <input
                                                                    type="file"
                                                                    id="img"
                                                                    className="d-none"
                                                                    accept="image/*"
                                                                    onChange={(e) => ImageChange(e)}
                                                                />
                                                                <label
                                                                    className="btn btn-outline-success shadow-lg m-1"
                                                                    for="img"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="top"
                                                                    title="Choose Logo"
                                                                >
                                                                    <IoImagesOutline size={25} />
                                                                </label>
                                                                {ImagePrv ? (
                                                                    <Fragment>
                                                                        <button
                                                                            className="btn btn-outline-success shadow-lg"
                                                                            for="file1"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="bottom"
                                                                            title="Remove selected image"
                                                                            type="button"
                                                                            onClick={() => ImageRemove()}
                                                                        >
                                                                            <IoRemoveCircleOutline size={25} />
                                                                        </button>
                                                                    </Fragment>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <table className="table table-hover table-borderless">
                                                        <tbody className='w-100'>

                                                            <tr>
                                                                <td className="py-2" scope="row">Id</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2 d-flex">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='number'
                                                                        placeholder='Id'
                                                                        name='Id'
                                                                        value={id}
                                                                        onChange={e => onChange(e)}
                                                                        minLength='6'
                                                                        required
                                                                        disabled
                                                                    />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-2">Title</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='text'
                                                                        placeholder='Title'
                                                                        name='Title'
                                                                        value={Title}
                                                                        onFocus={e => handleFocus(e)}
                                                                        onChange={e => onChange(e)}
                                                                        onBlur={(e) => onBlur(e)}
                                                                        maxLength='50'
                                                                        required
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Title ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Title}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                            <tr>
                                                                <td className="py-2" scope="row">Description</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='text'
                                                                        placeholder='Description'
                                                                        name='Description'
                                                                        value={Description}
                                                                        onFocus={e => handleFocus(e)}
                                                                        onChange={e => onChange(e)}
                                                                        onBlur={(e) => onBlur(e)}
                                                                        maxLength='50'
                                                                        required
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Description ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Description}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                            <tr>
                                                                <td className="py-2" scope="row">Specification</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='text'
                                                                        placeholder='Specification'
                                                                        name='Specification'
                                                                        value={Specification}
                                                                        onChange={e => onChange(e)}
                                                                        onBlur={(e) => onBlur(e)}
                                                                        required
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Specification ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Specification}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                            <tr>
                                                                <td className="py-2" scope="row">Product Unit</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        closeMenuOnSelect={false}
                                                                        borderRadius={"0px"}
                                                                        options={[{ value: 1, label: "Bolus" }, { value: 2, label: "Bottle" }, { value: 3, label: "Carton" }, { value: 4, label: "Bag" }]}
                                                                        name="Type"
                                                                        isMulti
                                                                        placeholder={"Please select products unit"}
                                                                        styles={colourStyles}
                                                                        value={Type}
                                                                        onChange={(e) => setFormData({ ...formData, Type: e })}
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Type ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Type}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                            <tr>
                                                                <td className="py-2" scope="row">Category</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        closeMenuOnSelect={false}
                                                                        borderRadius={"0px"}
                                                                        options={[{ value: 1, label: "Medicine" }, { value: 2, label: "Feed" }, { value: 3, label: "Chick" }]}
                                                                        name="Category"
                                                                        placeholder={"Select product category"}
                                                                        styles={colourStyles}
                                                                        value={Category ? { label: Category } : null}
                                                                        onChange={(e) => setFormData({ ...formData, Category: e.label })}
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Category ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Category}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                            <tr>
                                                                <td className="py-2" scope="row">Status</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        closeMenuOnSelect={false}
                                                                        borderRadius={"0px"}
                                                                        options={[{ value: 1, label: "Available" }, { value: 2, label: "Unavilable" }, { value: 3, label: "Withdraw" }]}
                                                                        name="Status"
                                                                        placeholder={"Select product status"}
                                                                        styles={colourStyles}
                                                                        value={Status ? { label: Status } : null}
                                                                        onChange={(e) => setFormData({ ...formData, Status: e.label, })}
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Status ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Status}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                        </tbody>

                                                    </table>

                                                    <div className="flex-1 text-center">
                                                        {
                                                            AccordLbl === "Update Product" ?
                                                                <button className='btn btn-outline-success form-rounded px-4  m-2' type='button' title="Update bank account" onClick={e => setUpdateModalShow(true)}>Update</button>
                                                                :
                                                                <button className='btn btn-outline-success form-rounded px-4' title="Add new bank account" type='button' onClick={e => setCreateModalShow(true)}>Submit</button>
                                                        }
                                                    </div>

                                                    {/* Add Bank Confirmation  */}
                                                    <CreateMessage
                                                        header="Add New Product Profile Profile"
                                                        body_header={Title}
                                                        body={"Are you sure want to add " + Title + "?"}
                                                        show={CreateModalShow}
                                                        Click={(e) => Create_Product_Pro(e)}
                                                        onHide={() => setCreateModalShow(false)}
                                                    />

                                                    {/* Update Confirmation  */}
                                                    <CreateMessage
                                                        header="Update Bank Info"
                                                        body_header={Title}
                                                        body={"Are you sure want to update " + Title + "?"}
                                                        show={UpdateModalShow}
                                                        Click={(e) => Update_Product_Pro(e)}
                                                        onHide={() => setUpdateModalShow(false)}
                                                    />

                                                    {/* Delete Confirmation */}
                                                    {/* <CreateMessage
                                                header="Remove Bank Info"
                                                body_header={BankName}
                                                body={"Are you sure want to remove " + BankName + " " + BankBranchName + " Branch info?"}
                                                show={DeleteModalShow}
                                                Click={(e) => DeleteBank(e)}
                                                onHide={() => setDeleteModalShow(false)}
                                            /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </div >
                            :
                            null
                    }

                    < div div className={`d-flex justify-content-between bg-white py-2 border-bottom ${Visibility}`}>
                        <h className='display-6 bg-white'>OVERVIEW</h>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "10vh" }}>
                            <input className="border rounded-pill px-2 min-vw-25" type="text" placeholder="Search Keywords" />
                            <p className='fw-bold text-success my-auto px-1' title="Search" type='button'>Search</p>
                        </div>



                    </div >
                </div >

            </div >
        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
    level: state.auth.level,
    sub_level: state.auth.sub_level,
    // SupplierID: props.location.SupplierID
});

export default connect(mapStateToProps, { logout })(Overview);