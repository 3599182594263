import * as moment from 'moment'
import { SaveProductPro, ProductProList, UpdateImage, UpdateProductPro } from '../../../../actions/SuppliersAPI';
import { FaCodeBranch, FaUserTie } from "react-icons/fa";
import React, { Fragment, useEffect, useState } from 'react';
import { IoCloudUploadOutline, IoImagesOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../../../actions/auth';
import { connect, useDispatch } from 'react-redux';

import { CreateMessage } from "../../../Modals/ModalForm.js";
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../../assets/error.png';
import infoIcon from '../../../../assets/info.png';
import successIcon from '../../../../assets/success.png';
import warningIcon from '../../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';

const Overview = ({ SupplierID, list, setList, setProdcutPro }) => {

    // 
    // const CompanyID = user.CompanyID;
    // const BranchID = user.BranchID;
    // const BranchName = user.BranchName;
    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [BankLists, setBankLists] = useState(initialValue)
    const [BBLists, setBBLists] = useState(initialValue)
    const [Data, setData] = useState(false)
    const [TempData, setTempData] = useState(false)
    const [Error, setError] = useState({});
    const [Step, setStep] = useState(null)
    const [RefLists, setRefLists] = useState(initialValue);
    const [RepLists, setRepLists] = useState(initialValue);
    const [Visibility, setVisibility] = useState("d-table");
    const [AccordLbl, setAccordLbl] = useState("Add New Product Profile");
    let toastProperties = null;
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        id: "",
        Title: "",
        Description: null,
        Specification: null,
        Type: null,
        Category: null,
        Status: null,
        Image: "",
        ImagePrv: ""
    });

    useEffect(() => {
        // LoadProductList()
    }, [])

    const LoadProductList = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var ProductList = await ProductProList(SupplierID);
        if (ProductList !== true)
            setData(ProductList.data);

        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const { id, Title, Description, Specification, Type, Category, Status, Image, ImagePrv } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onBlur = (e) => {

        if (e.target.value === "") { setFormData({ ...formData, [e.target.name]: TempData, }); }
    }

    const handleFocus = (e) => {
        setTempData(e.target.value)
        e.target.select()
    };

    const Create_Product_Pro = async e => {
        setCreateModalShow(false)
        setError({})
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: true
        });
        e.preventDefault();
        const result = await SaveProductPro(SupplierID, Title, Description, Specification, Type, Category, Status, Image, ImagePrv);

        if (result !== true) {
            if (result.error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Invalid Data',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Success',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                LoadProductList();
                AccordionToggle();
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
        } else {
            setList([...list, toastProperties = {
                id: 1,
                title: 'Error',
                description: "Failed to save product profile. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });

    };

    const Update_Product_Pro = async e => {
        setUpdateModalShow(false)
        setError({})
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: true
        });
        e.preventDefault();
        const result = await UpdateProductPro(SupplierID, id, Title, Description, Specification, Type, Category, Status, Image, ImagePrv);

        if (result !== true) {
            if (result.error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Invalid Data',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Success',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                LoadProductList();
                AccordionToggle();
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
        } else {
            setList([...list, toastProperties = {
                id: 1,
                title: 'Error',
                description: "Failed to update product profile. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });

    };


    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: 0, boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", height: "25px", borderRadius: '0px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                borderRadius: '20px',
            };
        },
        menu: base => ({
            ...base,
            borderRadius: '0px',
            outline: 0,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            padding: '5px'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '25px',
            padding: '0 3px',
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        })
    };

    const ImageChange = (e) => {
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: true
        });
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setFormData({ ...formData, "Image": file, "ImagePrv": reader.result })
        }

        reader.readAsDataURL(file)
        dispatch({
            type: DISPLAY_OVERLAY,
            payload: false
        });
    }

    const ImageRemove = () => {
        setFormData({ ...formData, "ImagePrv": '' })
    }

    const UploadImage = async e => {


        e.preventDefault();
        const result = await UpdateImage(id, Image);

    };

    const AccordionToggle = () => {
        setFormData({
            id: "",
            Title: "",
            Description: "",
            Specification: "",
            Type: null,
            Category: null,
            Status: null,
            Image: "",
            ImagePrv: ""
        });
        setStep(Step === "ProductAddition" ? null : "ProductAddition");
        setVisibility(Visibility === "d-none" ? "d-table" : "d-none")
        setAccordLbl("Add New Product Profile")
    }

    const EditProduct = (item) => {
        setFormData(item)
        setFormData({
            id: item.id,
            Title: item.Title,
            Description: item.Description,
            Specification: item.Specification,
            Type: item.Type,
            Category: item.Category,
            Status: item.Status,
            Image: "",
            ImagePrv: item.Image
        });

        setStep(Step === "ProductAddition" ? null : "ProductAddition");
        setVisibility(Visibility === "d-none" ? "d-table" : "d-none")
        setAccordLbl("Update Product")
    }


    return (
        <div className="position-relative h-100">
            <div className="position-absolute overflow-auto my-1 pb-5 w-100 h-75">

                <div className="row justify-content-center mx-auto d-table w-100 h-100">


                    < div div className={`d-flex justify-content-between bg-white py-2 border-bottom ${Visibility}`}>
                        <h className='display-6 bg-white'>OVERVIEW</h>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "10vh" }}>
                            <input className="border rounded-pill px-2 min-vw-25" type="text" placeholder="Search Keywords" />
                            <p className='fw-bold text-success my-auto px-1' title="Search" type='button'>Search</p>
                        </div>


                        {/* <table className={`table table-hover table-borderless table-responsive card-1 p-4 mx-auto rounded-0 ${Visibility}`}>
                        <thead>
                            <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                <th> <span>ID</span> </th>
                                <th> <span>Title</span> </th>
                                <th> <span>Category</span> </th>
                                <th> <span>Types</span> </th>
                                <th> <span>Status</span> </th>
                                <th> <span>Action</span> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Data ? Data.map((item) => (
                                    <tr className="border-bottom text-center" onClick={() => setProdcutPro(item)}>
                                        <td><span className="d-block font-weight-bold">{item.id}</span></td>
                                        <td><span className="d-block font-weight-bold">{item.Title}</span></td>
                                        <td><span className="d-block font-weight-bold">{item.Category}</span> </td>
                                        <td><span className="d-block font-weight-bold">{item.Type.map((item) => item.label + " ")}</span> </td>
                                        <td><span className="d-block font-weight-bold">{item.Status}</span> </td>
                                        <td className="px-3 py-0">
                                            <button className="btn fs-2 p-2 fad fa-trash text-danger" />
                                            <button className="btn fs-2 p-2 fad fa-edit text-success" onClick={() => EditProduct(item)} />
                                            <button className="btn fs-2 p-2 fad fa-eye text-success" />
                                            <button className="btn fs-2 fw-light p-2 fad fa-chevron-down" />
                                        </td>
                                    </tr>
                                ))
                                    : null
                            }
                        </tbody>
                    </table>
                 */}
                    </div >
                </div >

            </div >
        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
    // SupplierID: props.location.SupplierID
});

export default connect(mapStateToProps, { logout })(Overview);