/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';

import { Link, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { logout } from '../actions/auth';

// import { Container, Nav, NavDropdown } from 'react-bootstrap';
const Navbar = ({ logout, user, level, sub_level, NavBar, setNavBar, setActive, isActive }) => {

	const [redirect, setRedirect] = useState(false);
	const [DropDown, setDropDown] = useState(false);
	const [NavToggle, setNavToggle] = useState(false);


	const logout_user = (e) => {

		logout();
		setRedirect(true);
	};

	const BlurToggle = (e) => {

		if (e.relatedTarget === null)
			setDropDown(false)
		else if (e.relatedTarget.id === e.target.id) {
			setDropDown(true)
		}
		else {
			setDropDown(false)
		}
	}

	const guestLinks = () => (
		<Fragment>
			<li className='nav-item active'>
				<Link className='nav-link' to='/'>Home <span className='sr-only'>(current)</span></Link>
			</li>
			<li className='nav-item'>
				<Link className='nav-link' to='/login'>Login</Link>
			</li>
			{/* <li className='nav-item'>
				<Link className='nav-link' to='/signup'>Sign Up</Link>
			</li>
			<li className='nav-item'>
				<Link className='nav-link' to='/company_register'>Create A Business</Link>
			</li> */}
			{/* <Redirect to='/' /> */}
		</Fragment>
	);

	const WaitingView = () => (
		<Fragment>
			<li className='nav-item'>
				<a className='nav-link' href='#'>{!user ? "Loading" : user.FullName}</a>
			</li>
			<li className='nav-item'>
				<a className='nav-link' href='#!' onClick={logout_user}>Logout</a>
			</li>
			<Redirect to='/disable' />
		</Fragment>
	);

	const BlockView = () => (
		<Fragment>
			<li className='nav-item'>
				<a className='nav-link' href='#'>{!user ? "Loading" : user.FullName}</a>
			</li>
			<li className='nav-item'>
				<a className='nav-link' href='/' onClick={logout_user}>Logout</a>
			</li>
			<Redirect to='/block' />
		</Fragment>
	);

	const authLinks = () => (
		level === 3 ?
			<Fragment>
				<li className='nav-item active'>
					<Link className='nav-link' to='/home'>Home <span className='sr-only'>(current)</span></Link>
				</li>
				{user ?
					<li className="nav-item dropdown" onBlur={(e) => BlurToggle(e)}>
						<a className="nav-link dropdown-toggle show" href="#" id="dropdown09" data-bs-toggle="dropdown" aria-expanded="true"
							onClick={() => setDropDown(DropDown ? false : true)}

						>{user.FullName}</a>
						<ul className={`dropdown-menu ${DropDown ? "show" : null}`} aria-labelledby="dropdown09">
							<li><a className="dropdown-item" id="dropdown09" href="#">My Profile</a></li>
							<li><Link className="dropdown-item" id="dropdown09" to="/pvt_reset_pass">Change Password</Link></li>
							<li><a className="dropdown-item" id="dropdown09" href='/' onClick={logout_user}>Logout</a></li>
						</ul>
					</li>
					: null
				}
				<li className='nav-item'>
					<a className='nav-link' href='/' onClick={logout_user}>Logout</a>
				</li>
				{/* <Redirect to='/' /> */}
			</Fragment>
			:
			<Fragment>
				<li className='nav-item active'>
					<Link className='nav-link' to='/dashboard'>Home <span className='sr-only'>(current)</span></Link>
				</li>
				{user ?
					<li className="nav-item dropdown" onBlur={(e) => BlurToggle(e)}>
						<a className="nav-link dropdown-toggle show" href="#" id="dropdown09" data-bs-toggle="dropdown" aria-expanded="true"
							onClick={() => setDropDown(DropDown ? false : true)}

						>{user.FullName}</a>
						<ul className={`dropdown-menu ${DropDown ? "show" : null}`} aria-labelledby="dropdown09">
							<li><a className="dropdown-item" id="dropdown09" href="#">My Profile</a></li>
							<li><Link className="dropdown-item" id="dropdown09" to="/pvt_reset_pass">Change Password</Link></li>
							<li><a className="dropdown-item" id="dropdown09" href='/' onClick={logout_user}>Logout</a></li>
						</ul>
					</li>
					: null
				}
				<li className='nav-item'>
					<a className='nav-link' href='/' onClick={logout_user}>Logout</a>
				</li>
				{/* <Redirect to='/' /> */}
			</Fragment>
	);


	const FieldWorker = () => (
		<Fragment>
			<li className='nav-item active'>
				<Link className='nav-link' to='/field_work'>Home <span className='sr-only'>(current)</span></Link>
			</li>
			{user ?
				<li className="nav-item dropdown" onBlur={(e) => BlurToggle(e)}>
					<a className="nav-link dropdown-toggle show" href="#" id="dropdown09" data-bs-toggle="dropdown" aria-expanded="true"
						onClick={() => setDropDown(DropDown ? false : true)}

					>{user.FullName}</a>
					<ul className={`dropdown-menu ${DropDown ? "show" : null}`} aria-labelledby="dropdown09">
						<li><a className="dropdown-item" id="dropdown09" href="#">My Profile</a></li>
						<li><Link className="dropdown-item" id="dropdown09" to="/fwr_reset_pass">Change Password</Link></li>
						<li><a className="dropdown-item" id="dropdown09" href='/' onClick={logout_user}>Logout</a></li>
					</ul>
				</li>
				: null
			}
			<li className='nav-item'>
				<a className='nav-link' href='/' onClick={logout_user}>Logout</a>
			</li>
		</Fragment>
	);

	const ManagingDirector = () => (
		<Fragment>
			<li className='nav-item active'>
				<Link className='nav-link' to='/'>Home <span className='sr-only'>(current)</span></Link>
			</li>
			{user ?
				<li className="nav-item dropdown" onBlur={(e) => BlurToggle(e)}>
					<a className="nav-link dropdown-toggle show" href="#" id="dropdown09" data-bs-toggle="dropdown" aria-expanded="true"
						onClick={() => setDropDown(DropDown ? false : true)}

					>{user.FullName}</a>
					<ul className={`dropdown-menu ${DropDown ? "show" : null}`} aria-labelledby="dropdown09">
						<li><a className="dropdown-item" id="dropdown09" href="#">My Profile</a></li>
						<li><Link className="dropdown-item" id="dropdown09" to="/pvt_reset_pass">Change Password</Link></li>
						<li><a className="dropdown-item" id="dropdown09" href='/' onClick={logout_user}>Logout</a></li>
					</ul>
				</li>
				: null
			}
			<li className='nav-item'>
				<a className='nav-link' href='/' onClick={logout_user}>Logout</a>
			</li>
			{/* <Redirect to='/field_work' /> */}
		</Fragment>
	);

	const AdminView = () => (
		<Fragment>
			<li className='nav-item active'>
				<Link className='nav-link' to='/'>Home <span className='sr-only'>(current)</span></Link>
			</li>
			{user ?
				<li className="nav-item dropdown" onBlur={(e) => BlurToggle(e)}>
					<a className="nav-link dropdown-toggle show" href="#" id="dropdown09" data-bs-toggle="dropdown" aria-expanded="true"
						onClick={() => setDropDown(DropDown ? false : true)}

					>{user.FullName}</a>
					<ul className={`dropdown-menu ${DropDown ? "show" : null}`} aria-labelledby="dropdown09">
						<li><a className="dropdown-item" id="dropdown09" href="#">My Profile</a></li>
						<li><Link className="dropdown-item" id="dropdown09" to="/adm_reset_pass">Change Password</Link></li>
						<li><a className="dropdown-item" id="dropdown09" href='/' onClick={logout_user}>Logout</a></li>
					</ul>
				</li>
				: null
			}
			<li className='nav-item'>
				<a className='nav-link' href='/' onClick={logout_user}>Logout</a>
			</li>
			{/* <Redirect to='/field_work' /> */}
		</Fragment>
	);

	return (
		<nav className="navbar navbar-expand-lg navbar-theme rounded py-0 mb-2 border-bottom px-2" aria-label="Eleventh navbar example">
			{
				(level === 3 || level === 6 || level === 7 || level === 8 || level === 9) && sub_level !== 4 ?
					<a className="sidebar-toggle d-flex me-2" onClick={() => { NavBar ? setNavBar(false) : setNavBar(true) }}>
						<i class="fad fa-bars fs-2"></i></a> : null}

			<a className="navbar-brand" href="/">
				{
					level ? level === 0 || level === 1 ?
						<img src={`${process.env.REACT_APP_API_URL}/Media/logo.png`} className="img-fluid mx-auto d-table" width="120" height="40" alt="SoftaPoul" />
						: NavBar ?
							<img src={`${process.env.REACT_APP_API_URL}/Media/logo.png`} className="img-fluid mx-auto d-table" width="120" height="40" alt="SoftaPoul" />
							:
							(level === 7 || level === 8 || level === 9) || (level === 3 && sub_level !== 4) ?
								<img src={`${process.env.REACT_APP_API_URL}/Media/logo.png`} className="img-fluid mx-auto d-table" width="120" height="40" alt="SoftaPoul" />
								:
								<Fragment>
									<p className="fs-4 fw-bolder text-success m-0">{user ? user.CompanyName : null}</p>
									<h6 className="fw-normal text-success mb-0">{user ? user.BranchName + " Branch" : null}</h6>
								</Fragment>
						: <img src={`${process.env.REACT_APP_API_URL}/Media/logo.png`} className="img-fluid mx-auto d-table" width="120" height="40" alt="SoftaPoul" />
				}
			</a>
			<a
				className="navbar-toggler collapsed"
				data-bs-toggle="collapse"
				data-bs-target="#navbarsExample09"
				aria-controls="navbarsExample09"
				aria-expanded={NavToggle ? "false" : "true"}
				aria-label="Toggle navigation"
				onClick={() => { NavToggle ? setNavToggle(false) : setNavToggle(true) }}
			>
				<i className="hamburger align-self-center"></i>
			</a>

			<div className={NavToggle ? "navbar-collapse collapse show" : "navbar-collapse collapse"} id="navbarsExample09">
				<ul className="navbar-nav ms-auto">
					{
						typeof (level) !== 'undefined' && level !== null ?

							level === 0 ?
								BlockView() : level === 1 ?
									WaitingView() :
									level === 6 && sub_level ?
										ManagingDirector() :
										level === 9 && sub_level ?
											AdminView() :
											level === 3 && sub_level === 4 ?
												FieldWorker() :
												level === 3 && (sub_level === 1 || sub_level === 2 || sub_level === 3 || sub_level === 5 || sub_level === 6 || sub_level === 7 || sub_level === 9 || sub_level === 10) ?
													authLinks() :
													level === 7 || level === 8 || level === 9 ? authLinks()

														: guestLinks()

							: guestLinks()
					}
				</ul>
			</div>
		</nav>
	);
};

const mapStateToProps = state => ({
	user: state.auth.user,
	level: state.auth.level,
	sub_level: state.auth.sub_level
});

export default connect(mapStateToProps, { logout })(Navbar);
