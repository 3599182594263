import React, { useState, useEffect, Fragment } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import { MyStock, DeleteStock } from '../../../actions/InventoryAPI';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../actions/types';
import Select from 'react-select';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';

import { exportPDF } from '../../Suppliers/Class/OrderPDF';
import { Search } from 'semantic-ui-react';

import { UpdateModal, DeleteModal, InfoMessage } from "./Modals/ModalForm.js";
import { findUnique } from '../../../actions/APIHandler';

const Stock = ({ CompanyID, BranchID, SupplierID, user, list, setList, level, sub_level }) => {
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const [InfoModalShow, setInfoModalShow] = useState(false);
    const [BranchFilter, setBranchFilter] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [Data, setData] = useState(false)
    const [StockItem, setStockItem] = useState(false)
    const [OrderData, setOrderData] = useState([])
    const [Count, setCount] = useState(null)
    const [SearchKey, setSearchKey] = useState('')
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        LoadStock();
    }, [])

    const LoadStock = async () => {
        if (user !== null) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var result = await MyStock(user.CompanyID, user.BranchID);

            if (result.status === 200)
                setData(result.data);
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            history.push('/');
        }
    }

    const StockDelete = async e => {
        if (parseInt(StockItem.Weight) !== 0.000) {

            setDeleteModalShow(false)
            setInfoModalShow(true);
        } else {
            setDeleteModalShow(false)
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            e.preventDefault();
            const result = await DeleteStock(StockItem.id);
            if (result !== true) {
                LoadStock();
            }
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        }
    };

    const getTotal = () => {
        let TotalPrice = 0;
        const price = OrderData.map(row => row.Quantity * row.UnitPrice);
        if (price.length > 0) {
            TotalPrice = price.reduce((acc, val) => acc + val);
        }
        return TotalPrice;
    }
    const QuantityTotal = OrderData.reduce((TotalQuantity, myvalue) => TotalQuantity + parseInt(myvalue.Quantity, 10), 0);

    const deleteRow = (i) => {
        // make new rows. note: react state is immutable.
        const newRows = OrderData.splice(i, 1).concat(OrderData.slice(i + 1));
        // setRows({ OrderData: newRows })
        setCount(Count - 1)
    };


    const today = new Date().toLocaleDateString("en-us", "dd/MM/yyyy");

    const CScolourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: 0, boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", minWidth: "40vh", borderRadius: '20px' }),
        container: base => ({
            ...base,
            flex: 1,
        }),
    }

    let unique = Array.isArray(Data) && Data.length ? findUnique(Data, d => d.BranchID.BranchID) : null;
    let unique_search = Array.isArray(Data) && Data.length ? findUnique(Data, d => d.ItemCode.Title) : null;

    let StockList
    StockList = Array.isArray(Data) && Data.length ? Data.filter(function (item) {
        let BothValue = BranchFilter && SearchKey ? item.BranchID.BranchID === BranchFilter.value && item.ItemCode.id === SearchKey.value :
            BranchFilter ? item.BranchID.BranchID === BranchFilter.value :
                SearchKey ? item.ItemCode.id === SearchKey.value : item.Status === '1';

        return BothValue
    }).map(function ({ id, BranchID, COA, COA_Code, CompanyID, Cost, CreatedAt, InitStock, ItemCode, LastReceived, MinRequired, Qty, Status, UpdatedAt, UpdatedBy, Weight }) {
        return { id, BranchID, COA, COA_Code, CompanyID, Cost, CreatedAt, InitStock, ItemCode, LastReceived, MinRequired, Qty, Status, UpdatedAt, UpdatedBy, Weight };
    }) : null

    return (
        <div className="position-relative h-100"
        // style={{ height: "95%" }}
        >
            <div className="position-absolute overflow-auto my-1 w-100 h-100">

                {/* ORDER HISTORY */}
                <div className={`row justify-content-center mx-auto d-table w-100 h-100`}>

                    <div className={`d-flex justify-content-between bg-white py-2 mt-2 border-bottom`}>
                        <p className='display-6 bg-white fw-bold m-0'>PRODUCT STOCK</p>
                        {
                            level === 3 && (sub_level === 4 || sub_level === 9 || sub_level === 10) ?
                                <div className="d-flex justify-content-center mx-2">
                                    <Select
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        menuPortalTarget={document.body}
                                        borderRadius={"0px"}
                                        // options={Data.map}
                                        options={Array.isArray(unique) && unique.length ? unique.map((item) => ({ label: item.BranchID.BranchID + ". " + item.BranchID.Name + " Branch", value: item.BranchID.BranchID })) : []}
                                        defaultValue={{ label: "Select Dept", value: 0 }}
                                        name="Division"
                                        placeholder={"Branch"}
                                        styles={CScolourStyles}
                                        value={BranchFilter}
                                        onChange={(e) => setBranchFilter(e)}
                                        required
                                        id="Title"
                                        isClearable={true}
                                        isSearchable={true}
                                    />
                                </div>
                                : null
                        }
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "10vh", maxHeight: "4vh" }}>
                            <Select
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                borderRadius={"0px"}
                                // options={Data.map}
                                options={Array.isArray(unique_search) && unique_search.length ? unique_search.map((item) => ({ label: item.ItemCode.id + ". " + item.ItemCode.Title, value: item.ItemCode.id })) : []}
                                defaultValue={{ label: "Select Dept", value: 0 }}
                                name="Division"
                                placeholder={"Search"}
                                styles={CScolourStyles}
                                value={SearchKey}
                                onChange={(e) => setSearchKey(e)}
                                required
                                id="Title"
                                isClearable={true}
                                isSearchable={true}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                        </div>
                    </div>

                    {Array.isArray(StockList) && StockList.length ?
                        <table className={`table table-hover table-borderless table-responsive card-1 d-table mt-1`}>
                            <thead>
                                <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Code</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Title</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Unit (Wt)</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Unit Price</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Stock (Wt)</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Stock (Qt)</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Status</span></th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Last Received</span></th>
                                    <th className={`p-1 ${level === 3 && (sub_level === 4 || sub_level === 9 || sub_level === 10) ? "border-right" : null} `}> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Min Qt</span></th>
                                    {level === 3 && (sub_level === 4 || sub_level === 9 || sub_level === 10) ?
                                        <Fragment>
                                            <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Branch</span></th>
                                            <th className="p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase"> Action </span></th>
                                        </Fragment>
                                        : null}
                                </tr>
                            </thead>
                            {
                                StockList.map((item, i) => (
                                    <tbody>
                                        <tr className="border-bottom text-center" key={i}>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.ItemCode.id}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0">{item.ItemCode.Title}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{item.ItemCode.UnitWeight.toLocaleString("en", { minimumFractionDigits: 3 })}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{item.ItemCode.UnitPrice.toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{item.Weight.toLocaleString("en", { minimumFractionDigits: 3 })}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.Qty.toLocaleString("en", { minimumFractionDigits: 2 })} PCS</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.Status ? "Available" : "Unavailable"}</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{item.LastReceived.toLocaleString("en", { minimumFractionDigits: 2 })} PCS</span> </td>
                                            <td className={`p-1 ${level === 3 && (sub_level === 4 || sub_level === 9 || sub_level === 10) ? "border-right" : null} `}><span className="d-block fs-6 fw-bold text-right text-dark p-0">{item.MinRequired.toLocaleString("en", { minimumFractionDigits: 0 })} PCS</span> </td>
                                            {level === 3 && (sub_level === 4 || sub_level === 9 || sub_level === 10) ?
                                                <Fragment>
                                                    <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.BranchID.BranchID + ". " + item.BranchID.Name + " Branch"}</span> </td>
                                                    <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">
                                                        <button title="Remove Product" className="btn fs-5 px-2 py-0 fad fa-minus text-dark" onClick={() => { setStockItem(item); setDeleteModalShow(true) }} />
                                                        <button title="Update Product" className="btn fs-5 px-2 py-0 fad fa-edit text-dark" id="print" onClick={(e) => { setStockItem(item); setUpdateModalShow(true) }} />
                                                        <button title="Product Profile" className="btn fs-5 px-2 py-0 fad fa-eye text-dark" id="view" onClick={(e) => exportPDF(e, item)} />
                                                    </span>
                                                    </td>
                                                </Fragment>
                                                : null}

                                        </tr>
                                    </tbody>
                                ))
                            }
                        </table>
                        :
                        <div className={`d-flex justify-content-center align-items-center bg-white`}>
                            <h className='fs-2 fw-bold text-center text-success'>No Product Found!</h>
                        </div>
                    }
                </div>
            </div >
            {
                StockItem ?
                    <UpdateModal
                        Item={StockItem}
                        show={UpdateModalShow}
                        list={list}
                        setList={setList}
                        onReload={() => LoadStock()}
                        onHide={() => { setStockItem(false); setUpdateModalShow(false) }}
                    />

                    : null
            }
            {
                StockItem ?
                    <DeleteModal
                        FullName={StockItem.ItemCode.Title}
                        show={DeleteModalShow}
                        Click={(e) => StockDelete(e)}
                        onReload={() => LoadStock()}
                        onHide={() => { setStockItem(false); setDeleteModalShow(false) }}
                    />
                    : null
            }
            <InfoMessage
                header="Remove stock product!"
                body_header="Can not remove product"
                body="Product exist in physical store. So, you can not remove product without null stock"
                show={InfoModalShow}
                onHide={() => setInfoModalShow(false)}
            />
        </div >
    );
}

const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
    level: state.auth.level,
    sub_level: state.auth.sub_level,
});

export default connect(mapStateToProps, { logout })(Stock);