import * as moment from 'moment'
import { AllProductList } from '../../../../actions/APIHandler';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../../../actions/auth';
import { connect, useDispatch } from 'react-redux';

// import { CreateMessage } from "../../../Modals/ModalForm.js";
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../../assets/error.png';
import infoIcon from '../../../../assets/info.png';
import successIcon from '../../../../assets/success.png';
import warningIcon from '../../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';

const ProductItems = ({ SupplierID, user, list, setList }) => {
    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [BankLists, setBankLists] = useState(initialValue)
    const [BBLists, setBBLists] = useState(initialValue)
    const [Data, setData] = useState(false)
    const [TempData, setTempData] = useState(false)
    const [Error, setError] = useState({});
    const [Step, setStep] = useState(null)
    const [RefLists, setRefLists] = useState(initialValue);
    const [RepLists, setRepLists] = useState(initialValue);
    const [Visibility, setVisibility] = useState("d-table");
    const [AccordLbl, setAccordLbl] = useState("Add New Product");
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();

    const [formData, setFormData] = useState({
        id: "",
        Title: "",
        UnitWeight: null,
        UnitPrice: null,
        MRP: null,
        Type: null,
        Status: null
    });

    useEffect(() => {
        LoadProductItems()
    }, [])

    const LoadProductItems = async () => {
        if (user !== null) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var ProductItems = await AllProductList(SupplierID);
            if (ProductItems !== true)
                setData(ProductItems.data);

            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            history.push('/my_supplier');
        }
    }

    const EditProduct = (item) => {
        setFormData(item)
        setFormData({
            id: item.id,
            Title: item.Title,
            UnitWeight: item.UnitWeight,
            UnitPrice: item.UnitPrice,
            MRP: item.MRP,
            Type: item.Type,
            Status: item.Status,
        });

        setStep(Step === "ProductAddition" ? null : "ProductAddition");
        setVisibility(Visibility === "d-none" ? "d-table" : "d-none")
        setAccordLbl("Update Product")
    }


    return (
        <div className="position-relative h-100">
            <div className="position-absolute overflow-auto my-1 w-100 h-75">
                <div className="row justify-content-center mx-auto d-table w-100 h-100">

                    <div className={`d-flex justify-content-between bg-white py-2 mt-2 border-bottom ${Visibility}`}>
                        <h className='fs-3 bg-white fw-bold'>PRODUCT LISTS</h>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "10vh" }}>
                            <input className="border rounded-pill px-2 min-vw-25" type="text" placeholder="Search Keywords" />
                            <p className='fw-bold text-success my-auto px-1' title="Search" type='button'>Search</p>
                        </div>
                    </div>

                    {Array.isArray(Data) && Data.length ?
                        <table className={`table table-hover table-borderless table-responsive card-1 d-table mt-1 ${Visibility}`}>
                            <thead>
                                <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Code</span> </th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Title</span> </th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Pack Type</span> </th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Unit Weight</span> </th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Unit Price</span> </th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">MRP</span> </th>
                                    <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Status</span> </th>
                                    <th className="p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase">Action</span> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data.map((item, n) => (
                                        <tr className="border-bottom text-center" key={n}>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.ItemCode}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0">{item.Title}</span></td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.Type}</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.UnitWeight}</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.UnitPrice}</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.MRP}</span> </td>
                                            <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.Status}</span> </td>
                                            <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">
                                                <button className="btn fs-4 px-2 py-0 fad fa-minus text-danger" />
                                            </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        :
                        <tr className="text-center">
                            <td colSpan="8" className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">
                                No Product Items Found!
                            </span></td>
                        </tr>
                    }
                </div>
            </div >

        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(ProductItems);